@charset "utf-8";

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/noto-sans-kr-v13-latin_korean-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/noto-sans-kr-v13-latin_korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/noto-sans-kr-v13-latin_korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/noto-sans-kr-v13-latin_korean-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/noto-sans-kr-v13-latin_korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/noto-sans-kr-v13-latin_korean-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/noto-sans-kr-v13-latin_korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/noto-sans-kr-v13-latin_korean-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/noto-sans-kr-v13-latin_korean-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/noto-sans-kr-v13-latin_korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* RESET */
html,body{-webkit-text-size-adjust:none; color:#000; font-weight:400; line-height: 1.4; letter-spacing: -0.018em; font-family:'Poppins' , 'Noto Sans KR', 'malgun gothic' , '맑은 고딕' , sans-serif;}
body,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,th,td,form,fieldset,legend,input,textarea,button,select,img,div {margin:0; padding:0;}
body {position:relative; overflow:hidden; overflow-y:auto;}
img,fieldset{border:0}
button {background:none; border:0; cursor:pointer;}
ul,ol{list-style:none}
input,select{vertical-align:middle; background:#fff;}
em,address,i{font-style:normal}
table{border-collapse:collapse; border-spacing:0px; empty-cells:show; table-layout:fixed}
legend,caption{visibility:hidden; width:0; height:0; margin:0; padding:0; font-size:0; line-height:0;}
a {text-decoration:none;color:#000;}
a:hover,a:focus {text-decoration:none;}
input,button,select {outline:none; border-radius:0;}


/* 공통: 제어 */
.common_inner {position:relative; z-index:3; box-sizing:border-box; max-width:1174px; padding:0 32px; margin:0 auto;}
.common_inner:after {display:block; content:''; clear:both;}
.section_title {display:block; position:relative; z-index:55; max-width:1110px; min-height:68px; margin:0 auto; text-align:center; padding-top:152px; font: normal normal 600 56px/56px Poppins; letter-spacing:0px; color: #E83290; background: linear-gradient(to right, #E83290 15%, #8E42C9 30%, #5D7CE5 55%, #1E9CD5 80%, #E83290 95%);  background-size:300% auto; background-clip: text; text-fill-color: transparent; -webkit-background-clip: text; -webkit-text-fill-color: transparent; animation: shine 8s linear infinite;}
.section_title_sub {display:block; margin-top:15px; height:43px; line-height:43px; text-align:Center; text-transform:uppercase; font-size:30px;
    background: linear-gradient(to right, #E83290 15%, #8E42C9 30%, #5D7CE5 55%, #1E9CD5 80%, #E83290 95%);  background-size:300% auto; background-clip: text; text-fill-color: transparent; -webkit-background-clip: text; -webkit-text-fill-color: transparent; animation: shine 8s linear infinite;
}
@keyframes shine {
    to {
        background-position: 300% center;
    }
}

/* Slick 제어 */
.slick-dots {bottom:-57px !important;}
.slick-dots li button {padding:16px !important;}
.slick-dots li button:before {display:block !important; content:'' !important; width:12px !important; height:12px !important; background-color:#99366B !important; border-radius:100% !important; opacity:0.5 !important;}
.slick-dots .slick-active button:before {top:-1px !important; width:15px !important; height:15px !important; background:linear-gradient(151deg, #E83290 0%, #C11DCC 100%) !important;}

/* Nav */
#nav {position:absolute; left:0; top:0; z-index:9999; width:100%;}

.snb_current {display:block; }
.common_gnb_w {position:fixed; left:0; top:0; z-index:100; width:100%; height:158px; box-sizing:border-box; background-color:#fff;}
.grinbit_lnb_w {position:relative; z-index:10; margin:0 auto; box-sizing:border-box; width:100%; height:80px;}
.grinbit_lnb_w:after {display:block; content:''; clear:both;}
#nav.fixed {position:fixed; left:0; top:0; background-color:rgba(20,38,97,.9);}
.grinbit_h1_logo {display:inline-block; position:absolute; left:0; top:0; width:123px; height:30px; z-index:999; }
.grinbit_lnb_inner .grinbit_h1_logo {display:none;}
.logolink_main {display:block; position:relative; width:100%; height:100%; padding:21px 0;}
.icon_logo_gnb {top:50%; left:0; margin-top:-30px; width:192px; height:60px; }
.icon_logo_gnb svg {fill:#fff; width:100%;}
.grinbit_lnb_inner {transition:right .5s ease 0s; }
.grinbit_allmenu_w {display:none;}
.grinbit_lnb {position:relative; right:0; z-index:2; display:flex; width:100%; max-width:888px; padding:0 57px 0 90px; justify-content:space-between; margin:0 auto; box-sizing:border-box;}
.slnb_inner {position:relative; max-height:80px; overflow:hidden; transition: all ease-in-out 0.5s;}
.slnb_inner.last .grinbit_snb {right:15px;left:auto;}
.slnb_inner.last .ssnb_link {text-align:right;}
.slnb_link {position:relative; overflow:hidden; display:inline-block; padding:21px 10px; font-size:16px; max-width:180px; font-weight:700; text-transform:uppercase; box-sizing:border-box; color:#fff;}
.slnb_link:hover,
.slnb_link:active,
.slnb_link:focus,
#nav ul li.active a {text-decoration:none !important;}
.menu_hover .slnb_link:before,
.slnb_link.current:before {display:block;}
.slnb_txt_box {display:block; position:relative; padding:0 2px; overflow:hidden; height:38px; line-height:36px; letter-spacing:0.5px; text-overflow:ellipsis; white-space:nowrap; transition: all ease-in-out 0.2s;}
.slnb_link:hover .slnb_txt,
.slnb_link:active .slnb_txt,
#nav ul li.active a .slnb_txt {background-size: 100%; background-color:#C11DCC;
    background:linear-gradient(270deg, #C11DCC 0%, #E83290 100%) 0% 0% no-repeat padding-box;
    background-image: linear-gradient(270deg, #C11DCC, #E83290);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;}
.slnb_txt {font-weight:700; text-transform:uppercase; transition:opacity 0.5s ease 0s}
.icon_menu_arrow {display:none;}
.icon_menu_arrow {right:0; top:50%; margin-top:-4px; width:16px; height:8px; background-position:-20px -60px; }
.menu_hover .icon_menu_arrow {background-position:0 -60px; }
.btn_lang_w {position:absolute; right:0; top:24px; z-index:3;}
.btn_lang_cont {cursor:pointer;position:relative; z-index:3; }
.btn_lang_cont {display:inline-block; padding:7px 8px 7px;  box-sizing:border-box; font-size:13px; color:#fff; letter-spacing: -0.26px; font-weight:700; text-transform:uppercase;}
.btn_lang_cont .btn_lang_text {opacity:0.4;}
.btn_lang_cont.current .btn_lang_text {opacity:1;
    background:linear-gradient(270deg, #C11DCC 0%, #E83290 100%) 0% 0% no-repeat padding-box;
    background-image: linear-gradient(270deg, #C11DCC, #E83290);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    color:#C11DCC;
}
.btn_lang_cont.current:after {display:block; content:''; position:absolute; top:-3px; left:50%; margin-left:-2px; width:4px; height:4px; border-radius:100%; background-color:#C11DCC;}

.slnb_link:hover .slnb_txt_box:after,.slnb_link:hover .slnb_txt_box:before,
#nav ul li.active a .slnb_txt_box:after,#nav ul li.active a .slnb_txt_box:before {width:100%; }
.ssnb_txt_box:after,.ssnb_txt_box:before,
.slnb_txt_box:after,.slnb_txt_box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;
    width: 0;
    height:21px;
}
/* lnb bg효과 */
.ssnb_txt_box:before {
    transition: width .2s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
    background:linear-gradient(270deg, #C11DCC 0%, #E83290 100%) 0% 0% no-repeat padding-box;
}
.ssnb_txt_box:after {
    transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
    background:linear-gradient(270deg, #C11DCC 0%, #E83290 100%) 0% 0% no-repeat padding-box;
}
/* gnb 보더효과 */
.slnb_txt_box:before {
    transition: width .2s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
    background:linear-gradient(270deg, #C11DCC 0%, #E83290 100%) 0% 0% no-repeat padding-box;
    height:2px !important;
    top:auto;
    bottom:0;
    left:0;
}
.slnb_txt_box:after {
    transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
    background:linear-gradient(270deg, #C11DCC 0%, #E83290 100%) 0% 0% no-repeat padding-box;
    height:2px !important;
    top:auto;
    bottom:0;
    left:0;
}

/* 메뉴current일 경우 */
.ssnb_inner.current .ssnb_txt_box:after{width:100%;}
.slnb_link.current .slnb_txt_box:after {width:calc(100% - 5px);}
.ssnb_txt {position:Relative; z-index:2;}
.snb_bg {position:absolute; z-index:99; top:100px; left:50%; height:0; display:none; margin-left:-1280px; width:2560PX; transition: height 0.2s ease 0s;
    background:transparent linear-gradient(151deg, #1C0531F2 0%, #031333F2 100%) 0% 0% no-repeat padding-box
}
.snb_bg:after {
    display: block; content: ''; background:#ccc; opacity:0.4; position: absolute; left: 0; bottom: 0; z-index: 2; width: 100%; height: 2px;}
.lnbtype_main .snb_bg:after {opacity:1 !important;
    background: -moz-linear-gradient(left,  rgba(204,204,204,0) 0%, rgba(204,204,204,0.15) 50%, rgba(204,204,204,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(204,204,204,0) 0%,rgba(204,204,204,0.15) 50%,rgba(204,204,204,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(204,204,204,0) 0%,rgba(204,204,204,0.15) 50%,rgba(204,204,204,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cccccc', endColorstr='#00cccccc',GradientType=1 ); /* IE6-9 */
}

.snb_bg.snb_current {display:block; height:230px !important;}
.language_select_w {position:absolute; right:50%; top:39px; z-index:101; margin-right:-593px; overflow:hidden; transition: all ease-in-out 0.2s;}
.btn_language {display:block; position:relative; float:left; width:45px;  height:30px; line-height:30px; text-align:center; font-weight:400; color:#151515; opacity:0.4;}
.btn_language:hover {text-decoration:none;}
.lang_txt {display:block; font-size:13px; font-weight:400; letter-spacing:-0.65px; }
.lang_check {display:block; position:absolute; left:18px; top:0; width:8px; height:8px; background-position:-40px -70px; transition: all ease-in-out 0.3s;}
.lang_check.lang_eng {left:63px;}
.btn_language.current {opacity:1;}

/* 텍스트 흰색 */
.lnbtype_main .btn_language {color:#fff; }
.lnbtype_main .lang_check {background-position:-40px -80px;}

/* 메인 lnb 텍스트 흰색 */
.lnbtype_main {}
.lnbtype_main .grinbit_lnb_w {background-color:transparent;}

/* 스크롤 lnb */
.sticky {padding-top:0 !important; height:100px; background-color:#fff !important;  transition: all ease-in-out 0.3s;}
.sticky .grinbit_lnb_w {height:100px;}
.sticky .grinbit_logo {width:142px; height:50px; top:12px; }
.sticky .grinbit_lnb,.sticky .slnb_inner {height:100px;}
.sticky .snb_bg,.sticky .slnb_inner.menu_hover .grinbit_snb {top:100px;}

.btn_allmenu_x {display:none;}


/* --------------------  PC : Content --------------------  */
/* contents */
#contents {overflow:hidden;}
[class$="_cont_w"] {height: 100vh; box-sizing:border-box; overflow:hidden;}
/* PC:다운로드 */
#section1 {position:relative; text-align:center; height:100vh; background-color:#1C0531; overflow:hidden;}
#section1:after {display:block; content:''; position:absolute; left:0; top:0; z-index:2; width:100%; height:calc(100% - 6px); background:transparent url('../img/top_bg_e.png') 0% 0% repeat padding-box; opacity:0.4;}
.visual_video {z-index:1; -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
}
.download_app {
    /* z-index: 3; */
    width: 233px;
    height: 76px;
    position: absolute;
    top: 150px;
    right: 10px;
    background-image: url('../../assets/Images/bg_appdownload.png');
    background-size: 233px;
    background-repeat: no-repeat;
}
.appleAppStore {
    width: 46px;
    height: 46px;
    position: absolute;
    top: 40%;
    right: 32%;
}
.googleAppStore {
    width: 46px;
    height: 46px;
    position: absolute;
    top: 40%;
    right: 6.5%;
}
.visual_text_w {position:absolute; left:0; bottom:17%; right:0; z-index:3; text-align:center;}
.visual_title {font-weight:600; font-size:80px; color:#fff; text-shadow:0px 0px 10px #99366B99; }
.download_btn_w {display:inline-flex; overflow:hidden; margin-top:40px; text-align:center;}
[class^="down_btn"],
.submit_btn {position:relative; margin:0 10px; padding:0 20px; height:54px; line-height:56px;background: transparent linear-gradient(105deg, #F34079 0%, #FC8B4C 100%) 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #99366B; color:#fff; font-size:18px; font-weight:700;}
.down_btn_eng {font-size:19px;}
[class^="down_btn"]::after,.submit_btn:after { background: #F34079; box-shadow: 0px 0px 10px #99366B; content: ''; position: absolute; width: 0; height: 100%; left:0; top: 0; transition-duration: 0.3s; transform-origin: top left;}
[class^="down_btn"]:hover:after,
.submit_btn:hover:after {width:100%; height:100%; }
.down_text {padding:0 10px; }
.down_text, .icon_down,
.btn_text {position:relative; z-index:2;}
.icon_down {top:2px;}
.icon_down svg {fill:#fff;}
.section_arrow_down {position:absolute; left:0; bottom:0; width:100%; height:35px; text-align:center;}
.section_arrow_down img {display:inline-block; position:relative; z-index:5;}
.section_arrow_down svg {height:35px; bottom: -1px; overflow: hidden; position: absolute; left: 0; width: 100%; line-height: 0; direction: ltr; z-index: 8;}
.section_arrow_down svg path {fill:#1C0531 !important;}
.section_arrow_down svg path {fill: #fff; transform-origin: center; transform: rotateY(0deg);}

.elementor-shape {overflow: hidden; position: absolute; left: 0; width: 100%; line-height: 0; direction: ltr;}
.elementor-shape-top {top: -1px;}
.elementor-shape-top:not([data-negative=false]) svg {z-index:-1;}
.elementor-shape-bottom {bottom: -1px;}
.elementor-shape-bottom:not([data-negative=true]) svg {z-index: -1;}
.elementor-shape[data-negative=false].elementor-shape-bottom,.elementor-shape[data-negative=true].elementor-shape-top {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.elementor-shape svg {
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.elementor-shape .elementor-shape-fill {
    fill:#fff;
    -webkit-transform-origin:center;
    -ms-transform-origin:center;
    transform-origin:center;
    -webkit-transform:rotateY(0deg);
    transform:rotateY(0deg);
}

/* PC:서비스 */
#section2 {position:relative; height:872px; background-color:#1C0531;}
.service_bg {display:block; position:Absolute; right:-960px; bottom:0; min-width:1920px; width:1920px; height:100%; background: url('../img/section_bg/bg_service_2.png') left bottom no-repeat padding-box; background-size:942px 566px;}
#section2:before {display:block; content:''; min-width:1293px; position:absolute; left:0; top:0; width:1293px; height:786px; background: transparent url('../img/section_bg/bg_service_1.png') left top no-repeat padding-box; background-size:1293px 786px;}
#section2:after {display:block; content:''; position:absolute; left:0; bottom:0; width:100%; height:250px; background: transparent linear-gradient(0deg, #031333 0%, #03133300 100%) 0% 0% no-repeat padding-box;}
.md_middle_list {overflow:hidden; margin:0 auto; z-index:3;}
.md_middle_inner {opacity:0.2; width:812px; padding:0 0.33%; box-sizing:border-box;}
.md_middle_inner.slick-current {opacity:1;}
.md_mdl_cont {text-align:center; margin:0 auto; box-sizing:border-box;}
[class^="icon_service0"] {display:inline-block; width:100%; height:360px; text-align: center;}
.icon_service01 {}
.icon_service02 {}
.md_mdl_title {display:block; text-align:right; font: normal normal bold 54px/79px Noto Sans KR; letter-spacing: -0.54px; color: #FFFFFF; text-shadow: 0px 0px 4px #99366B;}
.md_mdl_text {display:none; margin-top:30px; font: normal normal normal 18px/26px Noto Sans KR; letter-spacing: -0.32px; color: #DDC5F2;}
.md_middle_inner.slick-current .md_mdl_text {display:block;}
.slick-current+.md_middle_inner {}
/* 슬릭 제어 */
.service_list_w {position:relative;}
.arrowSlider {min-width:812px; position:absolute; left:50%; top:50%; z-index:4; margin:-43px 0 0 -406px;}
[class^="arrowBtn_"] {position:absolute; width:86px; height:86px;}
.arrowBtn_next {right:0;}
.arrowBtn_prev {left:0;}
[class^="arrow_svg_"] {position:absolute; left:0; top:0;}
.arrow_hover {opacity:0;}
[class^="arrowBtn_"]:hover .arrow_hover {opacity:1;}


/* 서비스 애니메이션 */
.service_icon_w {margin:0 auto; padding-top:120px; width:460px; height:360px; box-sizing:border-box; }
.service_icon {opacity:0; display:block; position:relative; width:200px; height:200px; margin: 0 auto;}
.service_icon img {border-radius:9999px; width:400px; position:absolute; left:-100px; top:-91px; z-index:2;}
@keyframes cycle-colors {
    0% { border-color: hsl(0, 100%, 50%); }
    25% { border-color: hsl(90, 100%, 50%); }
    50% { border-color: hsl(180, 100%, 50%); }
    75% { border-color: hsl(270, 100%, 50%); }
    100% { border-color: hsl(360, 100%, 50%); }
}
@keyframes pulse {
    to {
        opacity: 0;
        transform: scale(1);
    }
}
.md_middle_inner.slick-current .service_icon {-webkit-animation-name: imgFadein; -webkit-animation-duration:0.5s; -webkit-animation-delay:0.5s; animation-fill-mode: forwards;}

@keyframes imgFadein {
    from {opacity: 0;}
    to { opacity: 1;}
}
@-moz-keyframes imgFadein { /* Firefox */
    from {opacity: 0;}
    to {opacity: 1;}
}
@-webkit-keyframes imgFadein { /* Safari and Chrome */
    from {opacity: 0;}
    to { opacity: 1;}
}
@-o-keyframes imgFadein { /* Opera */
    from {opacity: 0;}
    to {opacity: 1;}
}
.md_middle_inner.slick-current .service_icon::before,
.md_middle_inner.slick-current .service_icon::after {
    animation: pulse 2s linear infinite; border: #fff solid 8px;border-radius: 9999px;box-sizing: border-box; content: ' '; height: 140%; left: -20%; opacity: .6; position: absolute; top: -20%; transform: scale(0.714);  width: 140%; z-index: 1;
}
.md_middle_inner.slick-current .service_icon::after {animation-delay:1s;}
.md_middle_inner.slick-current .md_mdl_title {text-align:center;}
.md_middle_inner.slick-current + .md_middle_inner .md_mdl_title {text-align:left;}
/* 로드맵 */
#section3 {position:relative; overflow:hidden; padding-bottom:60px; height:902px; background-color:#031333;}
#section3:before {display:block; content:''; position:absolute; left:50%; top:0; margin-left:-960px; width:1920px; height:750px; background-image:url('../img/section_bg/bg_roadmap.png');}
.timelineGroup {display:flex;}
.timelineGroup:first-child {}
.timelineYear {position:relative; z-index:2; flex-shrink:0; width:95px; height:95px; line-height:95px; border-radius:100%; background: transparent linear-gradient(321deg, #8E42C9 0%, #E83290 100%) 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #8E42C9; text-align:center; font-size:25px; color:#fff;}
#timeline .timelineContainer {position: relative; margin-top:246px; width: 100%; height: 5.5rem; display: flex; flex-wrap: nowrap; flex-direction: row;  align-items: center; justify-content: flex-start;}
#timeline .timelineContainer .timelineElements {position: absolute; top: 0; box-sizing:border-box; padding-right:180px; display: flex; flex-wrap: nowrap; flex-direction: row; align-items:center; justify-content: flex-start; animation: timeline 8s ease-in-out alternate infinite;}
.timelineElements:after {display:block; content:''; position:absolute; left:0; top:50%; margin-top:-1px; width:calc(100% - 340px); height:2px; background:linear-gradient(90deg,#E83290,#8E42C9 50%,#8E42C9);}
@media screen and (min-width: 1329px) {
    #timeline .timelineContainer .timelineElements {
        animation: timeline 8s ease-in-out alternate infinite;
    }
}
@media screen and (max-width: 860px) {
    #timeline .timelineContainer .timelineElements {
        fanimation: timeline 10s ease-in-out alternate infinite;
    }
}
#timeline .timelineContainer:hover .timelineElements {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

.timelineNum_w {display:flex; justify-content:space-between; width:100%; padding:0 103px; box-sizing:border-box;}
.timelineNum {margin-left:147px;}
.timelineNum:first-child {margin-left:0;}
.group2023 {padding-right:46px;}
#timeline .timelineContainer .timelineElements .timelineNum {background: #8E42C9; border: 2px solid #020E2F; position: relative; top:15px; z-index: 2; width:37px; height:37px; display: inline-flex; flex-wrap: nowrap; flex-direction: column; align-items: center; justify-content: center; border-radius:100%; text-transform: uppercase; }
.numberText {display:block; width:100%; text-align:center; font-size:18px; color:#fff; font-weight:600;}
.timelineCont_list_w {position:absolute; left:16px; bottom:0; padding-bottom:120px; width:351px; font-size:18px; color:#fff;}
.timelineNum:nth-child(odd) .timelineCont_list_w {bottom:auto; top:0; padding-top:120px; padding-bottom:0;}
.timelineNum:nth-child(odd) {top:39px !important;}
.timelineCont_list_w:after {display:block; content:''; position:absolute; left:2px; bottom:50px; width:1px; height:60px; background:linear-gradient(#fff,#8E42C9);}
.timelineNum:nth-child(odd) .timelineCont_list_w:after {top:50px; bottom:auto; background:linear-gradient(#8E42C9,#fff);}
@keyframes timeline {
    0% {
        right: 0;
    }
    100% {
        right: calc( 100vw - 150rem);
    }
}
.tcl_text {position:relative; margin-top:12px; padding-left:15px;}
.tcl_text:first-child {margin-top:0;}
.tcl_text:after {display:block; content:''; position:absolute; left:0; top:10px; width:5px; height:5px; border-radius:100%; background-color:#fff;}
/* 2022 */
.group2022 .timelineYear {background: transparent linear-gradient(321deg, #5D7CE5 0%, #8E42C9 100%) 0% 0% no-repeat padding-box; box-shadow: 0px 0px 6px #8E42C9;}
.group2022 .timelineNum { background:#5D7CE5 !important; border: 2px solid #020E2F !important;}
.group2022 .timelineCont_list_w:after {display:block; content:''; position:absolute; left:2px; bottom:50px; width:1px; height:60px; background:linear-gradient(#fff,#5D7CE5);}
.group2022 .timelineNum:nth-child(odd) .timelineCont_list_w:after {top:50px; bottom:auto; background:linear-gradient(#5D7CE5,#fff);}

/* 파트너스 */
#section4 {position:relative; height:1143px; background-color:#031333; background-image:url('../img/bg_partners.png'); background-repeat:no-repeat; background-size:1920px auto; background-position:center bottom;}
#section4:after {display:block; content:''; position:Absolute; left:0; bottom:0; width:100%; height:190px; background-image:url('../img/bg_partners_bottom.png'); background-repeat:no-repeat; background-size:100% auto; min-width:1920px; background-position:center bottom; }
#section4 .common_inner { }
.partners_list_w {padding:0; max-width:calc(1110px + 94px); box-sizing:border-box;}
.partners_list {display:flex; width:calc(100% + 25px); flex-wrap:wrap; justify-content:space-flex-start;}
.partners_inner {display:table; flex-shrink:0; width:20%; padding:25px 25px 0 0; box-sizing:border-box;}
.partners_cont {display:table-cell; vertical-align:middle; padding:0 10px; box-sizing:border-box; width:100%; height:80px; background:#fff; border-radius:12px; text-align:center;}
.logo_kt .partners_cont {box-shadow: 1px 0px 15px #DA7EC0;}
.logo_skt .partners_cont {box-shadow: 0px -1px 15px #CE4197;}
.logo_lgu .partners_cont {box-shadow: 1px -1px 15px #FFA34F;}
.logo_sejong .partners_cont {box-shadow: -1px -1px 15px #5B85E5;}
.logo_kddi .partners_cont {box-shadow: 1px 0px 15px #8E42C9;}

.logo_military .partners_cont {box-shadow: 0px 0px 15px #FFA34F;}
.logo_army .partners_cont {box-shadow: -1px 0px 15px #8E42C9;}
.logo_airforce .partners_cont {box-shadow: 1px -1px 15px #DA7EC0;}
.logo_navy .partners_cont {box-shadow: 0px 0px 15px #CE4197;}
.logo_marine .partners_cont {box-shadow: 0px 0px 15px #5B85E5;}

.logo_justice .partners_cont {box-shadow: 1px 0px 15px #DA7EC0;}
.logo_calibration .partners_cont {box-shadow: 1px 0px 15px #5B85E5;}
.logo_sciencetec .partners_cont {box-shadow: -1px -1px 15px #FFA34F;}
.logo_propagation .partners_cont {box-shadow: 1px 1px 15px #8E42C9;}
.logo_health .partners_cont {box-shadow: -1px -1px 15px #DA7EC0;}

.logo_police .partners_cont {box-shadow: -1px 0px 15px #5B85E5;}
.logo_hanafinance .partners_cont {box-shadow: 1px 1px 15px #CE4197;}
.logo_hanabank .partners_cont {box-shadow: -1px 0px 15px #5B85E5;}
.logo_hyundai .partners_cont {box-shadow: 0px 0px 15px #DA7EC0;}
.logo_samsung .partners_cont {box-shadow: -1px 0px 15px #CE4197;}

.logo_payjoa .partners_cont {box-shadow: -1px 1px 15px #FFA34F;}
.logo_bizmarket .partners_cont {box-shadow: 1px -1px 15px #8E42C9;}
.logo_hellovision .partners_cont {box-shadow: 1px 0px 15px #DA7EC0;}
.logo_mezzomedia .partners_cont {box-shadow: 0px 1px 15px #CE4197;}
.logo_seerslab .partners_cont {box-shadow: 1px 0px 15px #FFA34F;}

.logo_dongseo .partners_cont {box-shadow: -1px 1px 15px #8E42C9;}
.logo_maeumsarang .partners_cont {box-shadow: 1px 1px 15px #5B85E5;}
.logo_jecheon .partners_cont {box-shadow: 1px 1px 15px #FFA34F;}
.logo_cheongdo .partners_cont {box-shadow: -1px 1px 15px #5B85E5;}
.logo_yangsan .partners_cont {box-shadow: 0px -1px 15px #DA7EC0;}

.logo_blockpatch .partners_cont {box-shadow: -1px 0px 15px #5B85E5;}
.logo_ninepartners .partners_cont {box-shadow: -1px 0px 15px #5B85E5;}
.logo_prunebombsoft .partners_cont {box-shadow: -1px 0px 15px #FFA34F;}

.partners_cont img {display:inline-block; max-width:100%;}

/* Our Team */
#section5 {position:Relative; overflow:hidden; height:100%; overflow:visible; background-color:#F0F7FF;}
#section5:after,
#section6:after {display:block; content:''; position:absolute; right:-250px; bottom:368px; width:1436px; height:1090px; background-image:url('../img/section_bg/bg_team.png'); background-repeat:no-repeat;}
.module_list {overflow:hidden; padding-top:10px; width:calc(100% + 25px); padding-bottom:120px;}
.module_inner {float:left; width:20%; padding-right:25px; padding-bottom:10px; margin-top:30px; box-sizing:border-box;}
.module_cont {display:block; position:relative; overflow:hidden; border-radius:20px; color: #5C677E; background-color:#fff; box-shadow: 0px 4px 10px #D4DFEC;}
.mb_inner,.module_box_w {position: relative;}
.mb_inner {display: block; width: 100%; z-index: 1; outline: none; text-decoration: none!important; color: inherit;}
.mb_image_w {z-index: 2; position:relative; width: 100%; padding-bottom:100%; background-color:#fff ;}
.mb_image_inner {position:Absolute; left:0; top:0; overflow:hidden; width:100%; height:100%;}
.mb_image {display: block; width:100%; -webkit-transition-duration: .3s; transition-duration: .3s; }
.module_cont:hover .mb_image {-webkit-transform: scale(1.05); transform: scale(1.05);}
.team_cont_w .mb_image {filter:grayscale(1);}
.team_cont_w .module_cont:hover .mb_image {filter:grayscale(0);}


.mb_text_w {padding:26px 15px 20px; text-align:center;}
[class^="mb_label_"] {display:block; position:Absolute; right:10px; bottom:-20px; z-index:2; text-align:center; width:40px; height:40px; line-height:43px; border-radius:100%;}
.mb_label_instar {background-color:#99366B; box-shadow:0px 0px 4px #99366B;}
.module_cont .icon_svg_in {fill:#fff;}
.module_cont:hover .mb_label_instar {background:#FCE40C; box-shadow:0px 0px 4px #99366B;}
.module_cont:hover .icon_svg_in {fill:#99366b;}
.mb_title {font-size:22px; letter-spacing:0; font-weight:700;}
.mb_subtext {margin-top:5px; overflow:hidden; height:46px; text-overflow:ellipsis; display:-webkit-box; -webkit-line-clamp:2; -webkit-box-orient:vertical; word-wrap:break-word; font-size:16px;}
.mb_subtext2 {margin-top:5px; overflow:hidden; height:46px; text-overflow:ellipsis; display:-webkit-box; -webkit-line-clamp:2; -webkit-box-orient:vertical; word-wrap:break-word; font-size:14px;}
.ourteam_list_w {position:relative; z-index:3;}

/* our advisor */
.advisor_cont_w {padding-bottom:20px;}
.advisor_cont_w .section_title {padding-top:132px;}
.advisor_cont_w {height:100%; position:relative; z-index:3; background-color:#fff; overflow:visible;}
.advisor_cont_w:after,.advisor_cont_w:before {display:block; content:''; width:100%; height:79px; position:absolute; left:0; top:-78px; z-index:3; background-image:url('../img/bg_advisor_top.png'); background-repeat:no-repeat; background-size:100% auto; background-position:center top;}
.advisor_cont_w:after {background-position:center bottom;}
.advisor_cont_w:before {top:auto; bottom:-78px; background-image:url('../img/bg_advisor_bottom.png'); }
.ouradvisor_list_w .module_list {padding-bottom:50px;}
.ouradvisor_list_w .module_cont {background-color:#F0F7FF; color:#5C677E;}
.ouradvisor_list_w .mb_text_w {padding-bottom:41px;}



/* News */
#section6 {position:relative; height:100%; background-color:#F0F7FF;}
#section6:after {right:auto; top:-365px;  left:-350px !important; transform:rotate(-360deg);}
.news_list_w .module_list {width:calc(100% + 30px);}
.news_list_w .module_inner {width:33.3%; padding-bottom:0;}
.news_list_w .module_cont {position:relative;}
.news_list_w .module_cont:hover:after {display:block; content:''; position:absolute; left:0; top:0; z-index:2; width:100%; height:100%; box-sizing:border-box; border:3px solid #99366B; border-radius:20px;}
.news_list_w .mb_image_w {padding-bottom:56.25%;}
.news_list_w .mb_text_w {text-align:left; padding:25px 20px;}
.news_list_w .mb_title {max-height:60px; overflow:hidden; text-overflow:ellipsis; display:-webkit-box; -webkit-line-clamp:2; -webkit-box-orient:vertical; word-wrap:break-word; font-size:18px;}
.news_list_w .mb_subtext {margin-top:10px; font-size:13px; opacity:0.7;}

/* contact */
#section7 {position:Relative; overflow:visible; height:100%; padding:190px 0 119px 0; background-color:#1C0531; background-image:url('../img/section_bg/bg_footer.png'); background-repeat:no-repeat; background-size:1920px auto; background-position:center bottom;}
#section7:after {display:block; content:''; position:absolute; left:0; top:0; width:100%; height:190px; background-image:url('../img/section_bg/bg_footer_top.png');background-repeat:no-repeat; background-size:100% auto; min-width:1920px; background-position:center bottom; background-color:#F0F7FF;}
.contact_cont_w .common_inner:after {display:block; content:''; clear:both;}
.contact_box_text {width:50%; float:left; padding-top:260px;}
.contact_title {display:block; width:100%; box-sizing:border-box; position:absolute; left:0; top:83px; padding:0 32px; font-size:60px; height:84px; letter-spacing: -1.2px; text-align:left; font-size:60px; letter-spacing:-1.2px; color: #FFF; animation:none;}
.section_sub_title {display:block; font-size:22px; letter-spacing:1.1px; color:#FFF;}
.contact_info_text {}
.info_title {display:block; margin-top:50px; font-size:16px; letter-spacing:1.28px; color: #FCFCFC;}
.cit_text {margin-top:20px; font-size:17px; letter-spacing:-0.17px; color:#FFFFFF; opacity: 0.6;}
.contact_sns_w {margin-top:50px;}
.info_title {letter-spacing: 1.28px; color: #FCFCFC;}
.contact_sns_list {position:relative; left:-5px; overflow:hidden;margin-top:15px; width:calc(100% + 10px);}
.sns_cont {float:left;}
.sns_link {display:block; padding:5px;}
.contact_box_form {float:right; width:50%; padding-top:60px; }
.contact_form {border-radius:20px; max-width:555px; padding:40px 50px; background-color:#fff;}
[class^="form_section_"] {display:block; margin-top:16px;}
[class^="form_section_"] label {display:block; font-size:16px; color:#232323; font-weight:700;}
.ip_text, .ip_textarea {position:relative; width:100%; margin-top:6px; min-height:28px; padding:0 10px; box-sizing:border-box; background-color:#F5F5F5; font-size:13px; color:#5B5B5B; border:0;}
.ip_text:focus {border:1px solid #d4d4d4;}
.ip_textarea {display:block; resize:none;height:220px; border:0; padding:9px 15px 7px; outline:none;}
.ip_textarea:focus {outline:1px solid #d4d4d4;}
.submit_btn {margin:16px 0 0; box-shadow:none; width:140px; height:40px; line-height:40px; font-size:15px;}
.submit_btn:after {box-shadow:none;}

/* -------------------- PC중간 bg 제어 -------------------- */
@media screen and (max-width:1920px) {
    #section4:after,
    #section2:before {width:1293px; left:50%; margin-left:-960px;}
    .service_bg {width:1920px; margin-right:-960px; right:50%; background-position:right bottom;}

}

@media screen and (max-width:1010px) {
    .visual_text_w {line-height:1.1;}
    .module_inner {width:25%;}
}

@media screen and (max-width:970px) {
    .grinbit_lnb {max-width:100%; padding-left:135px; padding-right:95px;}
    .visual_text_w {line-height:1.1;}
    .btn_lang_cont {padding:7px 4px 7px;}
}

/* -------------------- 태블릿 제어 -------------------- */
@media screen and (max-width:768px) {
    .section_title {font-size:44px; padding-top:120px; min-height:61px;}
    .section_title_sub {margin-top:0; height:25px; line-height:25px; font-size:18px;}
    .module_cont:hover {filter:grayscale(1);}
    .module_cont:hover .mb_label_instar {background-color:#99366B;}
    .module_cont:hover .icon_svg_in {fill:#fff;}

    /* T: nav */
    #nav.fixed {height:70px;}
    .common_gnb_w {height:75px; padding:0;}
    .grinbit_lnb_inner .grinbit_h1_logo {display:inline-block; width:123px; height:30px; position:Absolute; left:0; top:0;}
    .grinbit_lnb_w {}
    .logolink_main {display:inline-block; padding-left:16px;}
    .grinbit_logo_svg {fill:#fff;}
    .slnb_inner {padding:0 28px; height:45px;}
    .slnb_link {display:block; max-width:100%; padding:0;}
    .slnb_txt_box {display:inline-block;}
    .icon_logo_gnb {left:26px; margin-top:-15px; width:96px; height:30px;}
    .icon_logo_gnb svg {width:100%;}
    .grinbit_allmenu_w {display:block; position:absolute; right:0; top:0; width:85px; height:100%;}
    .btn_allmenu {display:block; position:absolute; right:0; top:0; padding:22px 32px;}
    .btn_allmenu svg {}

    /* 모바일 전체메뉴 */
    .grinbit_allmenu_w {}
    .grinbit_lnb_inner{float:none; padding-top:75px; position:fixed; right:-100%; top:0; z-index:999; width:100%; height:100vh; box-sizing:border-box; transition:right .5s ease; background:transparent linear-gradient(151deg, #1C0531F2 0%, #031333F2 100%) 0% 0% no-repeat padding-box; opacity: 0.95;}
    .grinbit_lnb {width:100%; box-sizing:border-box; display:inline-block; padding-top:119px; text-align:center;}
    /* 메뉴 닫기버튼 */
    .btn_allmenu_x {display:block; z-index:999; width:85px; height:75px; position:Absolute; right:0; top:0;}
    .btn_allmenu_x svg {fill:transparent linear-gradient(105deg, #c11dcc 0%, #e83290 100%) 0% 0% no-repeat padding-box;}
    .icon_close,
    .btn_popup_close .icon_close {left:50%; top:50%; margin:-10px 0 0 -10px; width:20px; height:19px; background-position:-40px -30px;}
    /* 메뉴 오픈 */
    .gnb_open .grinbit_lnb_inner { right:0; }
    .btn_lang_w {left:0; top:auto; bottom:131px; text-align:center; height:30px; z-index:9999;}
    .btn_lang_cont {color:#fff;}

    /* T: 다운로드 */
    #section1 { box-sizing:border-box;}
    .visual_text_w {bottom:170px;}
    .visual_title {font-size:46px;}
    [class^="down_btn"] {font-size:16px; height:46px; line-height:46px;}
    .down_btn_eng {font-size:17px;}
    .btn_scroll_down {bottom:0;}
    .section_arrow_down {bottom:0;}

    /* T : 서비스 */
    #section2 {height:670px; background-size:530px 318px;}
    #section2:before {width:550px; height:334px;}
    #section2:after {height:100px;}
    .md_middle_inner {width:464px; padding:0 0.15%;}
    .md_mdl_title {margin-top:15px; font-size:36px; line-height:42px;}
    .md_mdl_text {margin-top:10px; font-size:16px;}
    .service_icon_w {padding-top:60px; width:100%; height:280px;}
    .arrowSlider { min-width:auto; max-width:464px; width:100%; top:150px; margin:0 0 0 -232px;}
    [class^="arrowBtn_"] {width:50px; height:50px;}
    [class^="arrow_svg_"] {width:100%; height:100%;}
    .slick-list {overflow:visible !important;}

    /* T : 로드맵 */
    #section3 {height:auto; }
    #section3:before {transform:rotate(90deg); }
    .timelineElements:after {top:0; left:30px; width:1px; height:calc(100% - 105px);}
    #timeline .timelineContainer {display:block; width:343px; height:auto; margin:26px auto 0;}
    #timeline .timelineContainer .timelineElements {position:static; padding-right:0;}
    #timeline .timelineContainer .timelineElements,
    #timeline .timelineContainer .timelineElements .timelineNum {top:0 !important; margin-left:0; animation:none; display:block; width:100%; height:auto; background:none !important; border:0 !important;}
    .timelineYear {width:65px; height:65px; line-height:65px; font-size:18px;}
    .timelineCont_list_w {position:static; width:100%; font-size:16px; padding:0 0 0 81px; box-sizing:border-box;}
    .timelineCont_list_w:after {width:28px !important; height:1px !important; top:11px !important; left:40px !important; background:linear-gradient(105deg, #8E42C9 0%, #fff 100%) !important;}
    .group2022 .timelineCont_list_w:after {background:linear-gradient(105deg, #5D7CE5 0%, #fff 100%) !important; }
    .timelineNum:nth-child(odd) .timelineCont_list_w:after {left:65px !important;}
    .timelineNum_w {padding:20px 0 30px; display:block;}
    .timelineNum:nth-child(odd) .timelineCont_list_w {padding:0 0 0 103px;}
    .timelineGroup {animation:none; display:block; width:100%; padding:0 !important;}
    .timelineNum {margin-top:30px;}
    .numberText {position:absolute; left:3px; top:-5px;  width:29px; height:29px; line-height:29px; background:#8E42C9; border:2px solid #020E2F; border-radius:100%; font-size:14px;}
    .group2022 .numberText {background:#5D7CE5;}
    .timelineNum:nth-child(odd) .numberText {left:24px;}
    .tcl_text { min-height:29px;}
    .tcl_text:after {width:3px; height:3px;}
    /* T : 파트너스 */
    #section4 {height:694px;}
    .partners_list_w {padding-top:20px;}
    .partners_inner {padding:10px 10px 0 0;}
    .partners_list {width:calc(100% + 10px);}
    .partners_cont {width:128px; height:50px;}
    .partners_cont img {width:73%;}
    .logo_kt img {width:25px;}

    /* T : ourTeam */
    #section5:after {display:none;}
    #section5>.common_inner {overflow:hidden;}
    #section5>.common_inner:after {display:none; content:''; position:absolute; right:-1000px; bottom:-150px; width:1436px; height:1090px; background-image:url('../img/section_bg/bg_team.png'); background-repeat:no-repeat; background-position:right bottom; }
    #section6:after {left:-30px !important;}
    #section5:after, #section6:after {display:none;}
    .ourteam_list_w {}
    .team_cont_w .module_list {display:flex; flex-wrap:wrap; align-items:stretch;}
    .module_inner{width:33.3%;}
    .advisor_cont_w .section_title {padding-top:90px;}
    .ouradvisor_list_w .module_list {padding-bottom:70px; display:flex; flex-wrap:wrap; align-items:stretch;}
    .ouradvisor_list_w .module_inner,
    .team_cont_w .module_inner {flex-shrink:0;}
    .news_list_w .module_cont:hover:after {display:none;}
    .news_list_w .module_cont:hover {filter:grayscale(0);}

    .ouradvisor_list_w .module_list {display:flex; align-items: stretch;}
    .ouradvisor_list_w .module_inner {flex-shrink:0;}
    .ouradvisor_list_w .module_cont {min-height:auto; height:100%; }
    .ouradvisor_list_w .mb_text_w {padding:25px 15px; min-height:Auto; }
    .ouradvisor_list_w .module_box_w {position:relative;}


    /* T : contact */
    .contact_form {padding:22px 22px 40px;}
    .contact_box_text {padding-top:215px;}
    .contact_title {top:77px; font-size:44px; height:61px;}
    .contact_info_text {}
    .section_sub_title {}
    .info_title {margin-top:30px;}
    .cit_text {font-size:14px;letter-spacing:-0.14px; line-height:26px;}
    .contact_sns_w{}
    .info_title {}
    .contact_sns_list {width:200px;}
    .sns_cont {}
    .sns_link {}
    [class^="form_section_"] label {font-size:15px;}
    .ip_text, .ip_textarea {min-height:38px;}
    .submit_btn {width:100%;}

    #section7:after {min-width:auto; }
}




/* -------------------- 모바일 제어 -------------------- */
@media screen and (max-width:640px) {
    .common_inner {padding:0 16px;}
    .section_title {padding-top:110px;}
    .visual_title {font-size:34px;}
    .download_btn_w {display:block;}
    .down_btn_eng {margin-top:12px;}
    .grinbit_lnb_inner {padding-top:138px;}
    .grinbit_lnb {padding:0; }
    .slnb_link {font-size:20px;}

    /* M : 서비스 */
    #section2 {}
    #section2:before {background-size:100% auto; left:-120px;}
    #section4:after, #section2:before {min-width:auto; width:1080px; left:-70px; margin-left:0; background-size:100% auto;}
    .arrowSlider {left:0; margin:-16px 0 0;}
    .md_mdl_title,
    .md_mdl_text {padding:0 10px; word-break:keep-all;}
    /* M : 로드맵 */
    #section3 {padding-bottom:50px;}
    .tcl_text {padding-right:16px;}
    .timelineElements:after {left:32px;}
    .timelineNum:nth-child .numberText {left:7px;}
    .timelineNum:nth-child(odd) .numberText {left:29px;}
    /* M : 파트너스 */
    #section4 {height:auto; padding-bottom:130px;}
    #section4 .common_inner {padding:0;}
    .partners_list_w {overflow:hidden; overflow-x:auto; }
    .partners_list {width:125%; padding:0 7px 0 16px; box-sizing:border-box;}
    .partners_inner {width:137px; padding:9px 9px 0 0; box-sizing:border-box;}
    .partners_cont{padding:0 18px;}
    /* M : News */
    .news_list_w .module_list {width:100%;}
    .news_list_w .module_inner {padding-right:0;}
    .news_cont_w .mb_title {font-size:16px;}


    /* M : Advisor */
    .module_inner {width:50%;}
    .ouradvisor_list_w .module_cont {height:auto !important;}
    [class^="mb_label_"] {width:32px; height:32px; line-height:34px;}
    .mb_text_w {padding:16px 15px 20px;}
    .mb_title {font-size:16px;}
    .mb_subtext {font-size:12px;}
    .mb_subtext{height:32px;}
    .team_cont_w .module_cont {width:100% !important; height:auto !important;}


    /* M : contact */
    #section7 {padding-top:100px;}
    .contact_title {position:static; width:100%; text-align:center;}
    .contact_box_form {margin-top:41px; float:none; width:100%; padding:0;}
    .contact_box_text {width:100%; padding-top:68px; text-align:center;}
    .info_title {margin-top:50px;}
    .cit_text {margin-top:23px;}
    .contact_sns_list {display:block; margin:15px auto 0; width:225px; text-align:center;}
    .sns_cont {float:none; display:inline-block; }
    #section7:after {width:768px; height:76px; background-size:100% auto; left:0; bottom:0; }

}



