@charset "utf-8";

.circle_container_w {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left:0;
    bottom:0;
    z-index:2;
}

.background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    -webkit-mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
    mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
}

.circle-container {
    position: absolute;
    transform: translateY(-10vh);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
.circle-container .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(#99ffff, #99ffff 10%, rgba(153, 255, 255, 0) 56%);
    -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@-webkit-keyframes fade-frames {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-frames {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes scale-frames {
    0% {
        transform: scale3d(0.4, 0.4, 1);
    }
    50% {
        transform: scale3d(2.2, 2.2, 1);
    }
    100% {
        transform: scale3d(0.4, 0.4, 1);
    }
}
@keyframes scale-frames {
    0% {
        transform: scale3d(0.4, 0.4, 1);
    }
    50% {
        transform: scale3d(2.2, 2.2, 1);
    }
    100% {
        transform: scale3d(0.4, 0.4, 1);
    }
}
.circle-container:nth-child(1) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-1;
    animation-name: move-frames-1;
    -webkit-animation-duration: 31698ms;
    animation-duration: 31698ms;
    -webkit-animation-delay: 24363ms;
    animation-delay: 24363ms;
}
@-webkit-keyframes move-frames-1 {
    from {
        transform: translate3d(9vw, 108vh, 0);
    }
    to {
        transform: translate3d(43vw, -113vh, 0);
    }
}
@keyframes move-frames-1 {
    from {
        transform: translate3d(9vw, 108vh, 0);
    }
    to {
        transform: translate3d(43vw, -113vh, 0);
    }
}
.circle-container:nth-child(1) .circle {
    -webkit-animation-delay: 764ms;
    animation-delay: 764ms;
}
.circle-container:nth-child(2) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-2;
    animation-name: move-frames-2;
    -webkit-animation-duration: 28405ms;
    animation-duration: 28405ms;
    -webkit-animation-delay: 33766ms;
    animation-delay: 33766ms;
}
@-webkit-keyframes move-frames-2 {
    from {
        transform: translate3d(20vw, 106vh, 0);
    }
    to {
        transform: translate3d(16vw, -123vh, 0);
    }
}
@keyframes move-frames-2 {
    from {
        transform: translate3d(20vw, 106vh, 0);
    }
    to {
        transform: translate3d(16vw, -123vh, 0);
    }
}
.circle-container:nth-child(2) .circle {
    -webkit-animation-delay: 1845ms;
    animation-delay: 1845ms;
}
.circle-container:nth-child(3) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-3;
    animation-name: move-frames-3;
    -webkit-animation-duration: 34763ms;
    animation-duration: 34763ms;
    -webkit-animation-delay: 28705ms;
    animation-delay: 28705ms;
}
@-webkit-keyframes move-frames-3 {
    from {
        transform: translate3d(14vw, 109vh, 0);
    }
    to {
        transform: translate3d(92vw, -117vh, 0);
    }
}
@keyframes move-frames-3 {
    from {
        transform: translate3d(14vw, 109vh, 0);
    }
    to {
        transform: translate3d(92vw, -117vh, 0);
    }
}
.circle-container:nth-child(3) .circle {
    -webkit-animation-delay: 3991ms;
    animation-delay: 3991ms;
}
.circle-container:nth-child(4) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-4;
    animation-name: move-frames-4;
    -webkit-animation-duration: 35791ms;
    animation-duration: 35791ms;
    -webkit-animation-delay: 10167ms;
    animation-delay: 10167ms;
}
@-webkit-keyframes move-frames-4 {
    from {
        transform: translate3d(83vw, 103vh, 0);
    }
    to {
        transform: translate3d(56vw, -115vh, 0);
    }
}
@keyframes move-frames-4 {
    from {
        transform: translate3d(83vw, 103vh, 0);
    }
    to {
        transform: translate3d(56vw, -115vh, 0);
    }
}
.circle-container:nth-child(4) .circle {
    -webkit-animation-delay: 3650ms;
    animation-delay: 3650ms;
}
.circle-container:nth-child(5) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-5;
    animation-name: move-frames-5;
    -webkit-animation-duration: 36516ms;
    animation-duration: 36516ms;
    -webkit-animation-delay: 32125ms;
    animation-delay: 32125ms;
}
@-webkit-keyframes move-frames-5 {
    from {
        transform: translate3d(64vw, 106vh, 0);
    }
    to {
        transform: translate3d(60vw, -133vh, 0);
    }
}
@keyframes move-frames-5 {
    from {
        transform: translate3d(64vw, 106vh, 0);
    }
    to {
        transform: translate3d(60vw, -133vh, 0);
    }
}
.circle-container:nth-child(5) .circle {
    -webkit-animation-delay: 2034ms;
    animation-delay: 2034ms;
}
.circle-container:nth-child(6) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-6;
    animation-name: move-frames-6;
    -webkit-animation-duration: 36139ms;
    animation-duration: 36139ms;
    -webkit-animation-delay: 22701ms;
    animation-delay: 22701ms;
}
@-webkit-keyframes move-frames-6 {
    from {
        transform: translate3d(52vw, 108vh, 0);
    }
    to {
        transform: translate3d(100vw, -136vh, 0);
    }
}
@keyframes move-frames-6 {
    from {
        transform: translate3d(52vw, 108vh, 0);
    }
    to {
        transform: translate3d(100vw, -136vh, 0);
    }
}
.circle-container:nth-child(6) .circle {
    -webkit-animation-delay: 2759ms;
    animation-delay: 2759ms;
}
.circle-container:nth-child(7) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-7;
    animation-name: move-frames-7;
    -webkit-animation-duration: 31455ms;
    animation-duration: 31455ms;
    -webkit-animation-delay: 11953ms;
    animation-delay: 11953ms;
}
@-webkit-keyframes move-frames-7 {
    from {
        transform: translate3d(1vw, 102vh, 0);
    }
    to {
        transform: translate3d(71vw, -118vh, 0);
    }
}
@keyframes move-frames-7 {
    from {
        transform: translate3d(1vw, 102vh, 0);
    }
    to {
        transform: translate3d(71vw, -118vh, 0);
    }
}
.circle-container:nth-child(7) .circle {
    -webkit-animation-delay: 34ms;
    animation-delay: 34ms;
}
.circle-container:nth-child(8) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-8;
    animation-name: move-frames-8;
    -webkit-animation-duration: 36328ms;
    animation-duration: 36328ms;
    -webkit-animation-delay: 18236ms;
    animation-delay: 18236ms;
}
@-webkit-keyframes move-frames-8 {
    from {
        transform: translate3d(54vw, 106vh, 0);
    }
    to {
        transform: translate3d(32vw, -134vh, 0);
    }
}
@keyframes move-frames-8 {
    from {
        transform: translate3d(54vw, 106vh, 0);
    }
    to {
        transform: translate3d(32vw, -134vh, 0);
    }
}
.circle-container:nth-child(8) .circle {
    -webkit-animation-delay: 2653ms;
    animation-delay: 2653ms;
}
.circle-container:nth-child(9) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-9;
    animation-name: move-frames-9;
    -webkit-animation-duration: 28420ms;
    animation-duration: 28420ms;
    -webkit-animation-delay: 544ms;
    animation-delay: 544ms;
}
@-webkit-keyframes move-frames-9 {
    from {
        transform: translate3d(24vw, 106vh, 0);
    }
    to {
        transform: translate3d(47vw, -107vh, 0);
    }
}
@keyframes move-frames-9 {
    from {
        transform: translate3d(24vw, 106vh, 0);
    }
    to {
        transform: translate3d(47vw, -107vh, 0);
    }
}
.circle-container:nth-child(9) .circle {
    -webkit-animation-delay: 1408ms;
    animation-delay: 1408ms;
}
.circle-container:nth-child(10) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-10;
    animation-name: move-frames-10;
    -webkit-animation-duration: 33623ms;
    animation-duration: 33623ms;
    -webkit-animation-delay: 15992ms;
    animation-delay: 15992ms;
}
@-webkit-keyframes move-frames-10 {
    from {
        transform: translate3d(71vw, 106vh, 0);
    }
    to {
        transform: translate3d(84vw, -127vh, 0);
    }
}
@keyframes move-frames-10 {
    from {
        transform: translate3d(71vw, 106vh, 0);
    }
    to {
        transform: translate3d(84vw, -127vh, 0);
    }
}
.circle-container:nth-child(10) .circle {
    -webkit-animation-delay: 3944ms;
    animation-delay: 3944ms;
}
.circle-container:nth-child(11) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-11;
    animation-name: move-frames-11;
    -webkit-animation-duration: 30553ms;
    animation-duration: 30553ms;
    -webkit-animation-delay: 13855ms;
    animation-delay: 13855ms;
}
@-webkit-keyframes move-frames-11 {
    from {
        transform: translate3d(6vw, 104vh, 0);
    }
    to {
        transform: translate3d(16vw, -132vh, 0);
    }
}
@keyframes move-frames-11 {
    from {
        transform: translate3d(6vw, 104vh, 0);
    }
    to {
        transform: translate3d(16vw, -132vh, 0);
    }
}
.circle-container:nth-child(11) .circle {
    -webkit-animation-delay: 3850ms;
    animation-delay: 3850ms;
}
.circle-container:nth-child(12) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-12;
    animation-name: move-frames-12;
    -webkit-animation-duration: 28848ms;
    animation-duration: 28848ms;
    -webkit-animation-delay: 7425ms;
    animation-delay: 7425ms;
}
@-webkit-keyframes move-frames-12 {
    from {
        transform: translate3d(85vw, 107vh, 0);
    }
    to {
        transform: translate3d(40vw, -122vh, 0);
    }
}
@keyframes move-frames-12 {
    from {
        transform: translate3d(85vw, 107vh, 0);
    }
    to {
        transform: translate3d(40vw, -122vh, 0);
    }
}
.circle-container:nth-child(12) .circle {
    -webkit-animation-delay: 426ms;
    animation-delay: 426ms;
}
.circle-container:nth-child(13) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-13;
    animation-name: move-frames-13;
    -webkit-animation-duration: 35863ms;
    animation-duration: 35863ms;
    -webkit-animation-delay: 27485ms;
    animation-delay: 27485ms;
}
@-webkit-keyframes move-frames-13 {
    from {
        transform: translate3d(60vw, 103vh, 0);
    }
    to {
        transform: translate3d(89vw, -105vh, 0);
    }
}
@keyframes move-frames-13 {
    from {
        transform: translate3d(60vw, 103vh, 0);
    }
    to {
        transform: translate3d(89vw, -105vh, 0);
    }
}
.circle-container:nth-child(13) .circle {
    -webkit-animation-delay: 722ms;
    animation-delay: 722ms;
}
.circle-container:nth-child(14) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-14;
    animation-name: move-frames-14;
    -webkit-animation-duration: 33662ms;
    animation-duration: 33662ms;
    -webkit-animation-delay: 30084ms;
    animation-delay: 30084ms;
}
@-webkit-keyframes move-frames-14 {
    from {
        transform: translate3d(82vw, 104vh, 0);
    }
    to {
        transform: translate3d(79vw, -107vh, 0);
    }
}
@keyframes move-frames-14 {
    from {
        transform: translate3d(82vw, 104vh, 0);
    }
    to {
        transform: translate3d(79vw, -107vh, 0);
    }
}
.circle-container:nth-child(14) .circle {
    -webkit-animation-delay: 2995ms;
    animation-delay: 2995ms;
}
.circle-container:nth-child(15) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-15;
    animation-name: move-frames-15;
    -webkit-animation-duration: 36716ms;
    animation-duration: 36716ms;
    -webkit-animation-delay: 4449ms;
    animation-delay: 4449ms;
}
@-webkit-keyframes move-frames-15 {
    from {
        transform: translate3d(32vw, 103vh, 0);
    }
    to {
        transform: translate3d(10vw, -126vh, 0);
    }
}
@keyframes move-frames-15 {
    from {
        transform: translate3d(32vw, 103vh, 0);
    }
    to {
        transform: translate3d(10vw, -126vh, 0);
    }
}
.circle-container:nth-child(15) .circle {
    -webkit-animation-delay: 2475ms;
    animation-delay: 2475ms;
}
.circle-container:nth-child(16) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-16;
    animation-name: move-frames-16;
    -webkit-animation-duration: 33152ms;
    animation-duration: 33152ms;
    -webkit-animation-delay: 23801ms;
    animation-delay: 23801ms;
}
@-webkit-keyframes move-frames-16 {
    from {
        transform: translate3d(12vw, 101vh, 0);
    }
    to {
        transform: translate3d(79vw, -128vh, 0);
    }
}
@keyframes move-frames-16 {
    from {
        transform: translate3d(12vw, 101vh, 0);
    }
    to {
        transform: translate3d(79vw, -128vh, 0);
    }
}
.circle-container:nth-child(16) .circle {
    -webkit-animation-delay: 1102ms;
    animation-delay: 1102ms;
}
.circle-container:nth-child(17) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-17;
    animation-name: move-frames-17;
    -webkit-animation-duration: 31229ms;
    animation-duration: 31229ms;
    -webkit-animation-delay: 33011ms;
    animation-delay: 33011ms;
}
@-webkit-keyframes move-frames-17 {
    from {
        transform: translate3d(73vw, 108vh, 0);
    }
    to {
        transform: translate3d(10vw, -133vh, 0);
    }
}
@keyframes move-frames-17 {
    from {
        transform: translate3d(73vw, 108vh, 0);
    }
    to {
        transform: translate3d(10vw, -133vh, 0);
    }
}
.circle-container:nth-child(17) .circle {
    -webkit-animation-delay: 1585ms;
    animation-delay: 1585ms;
}
.circle-container:nth-child(18) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-18;
    animation-name: move-frames-18;
    -webkit-animation-duration: 36858ms;
    animation-duration: 36858ms;
    -webkit-animation-delay: 17340ms;
    animation-delay: 17340ms;
}
@-webkit-keyframes move-frames-18 {
    from {
        transform: translate3d(73vw, 108vh, 0);
    }
    to {
        transform: translate3d(46vw, -137vh, 0);
    }
}
@keyframes move-frames-18 {
    from {
        transform: translate3d(73vw, 108vh, 0);
    }
    to {
        transform: translate3d(46vw, -137vh, 0);
    }
}
.circle-container:nth-child(18) .circle {
    -webkit-animation-delay: 3895ms;
    animation-delay: 3895ms;
}
.circle-container:nth-child(19) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-19;
    animation-name: move-frames-19;
    -webkit-animation-duration: 34732ms;
    animation-duration: 34732ms;
    -webkit-animation-delay: 36659ms;
    animation-delay: 36659ms;
}
@-webkit-keyframes move-frames-19 {
    from {
        transform: translate3d(47vw, 107vh, 0);
    }
    to {
        transform: translate3d(85vw, -110vh, 0);
    }
}
@keyframes move-frames-19 {
    from {
        transform: translate3d(47vw, 107vh, 0);
    }
    to {
        transform: translate3d(85vw, -110vh, 0);
    }
}
.circle-container:nth-child(19) .circle {
    -webkit-animation-delay: 1493ms;
    animation-delay: 1493ms;
}
.circle-container:nth-child(20) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-20;
    animation-name: move-frames-20;
    -webkit-animation-duration: 30468ms;
    animation-duration: 30468ms;
    -webkit-animation-delay: 32794ms;
    animation-delay: 32794ms;
}
@-webkit-keyframes move-frames-20 {
    from {
        transform: translate3d(38vw, 108vh, 0);
    }
    to {
        transform: translate3d(37vw, -120vh, 0);
    }
}
@keyframes move-frames-20 {
    from {
        transform: translate3d(38vw, 108vh, 0);
    }
    to {
        transform: translate3d(37vw, -120vh, 0);
    }
}
.circle-container:nth-child(20) .circle {
    -webkit-animation-delay: 1243ms;
    animation-delay: 1243ms;
}
.circle-container:nth-child(21) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-21;
    animation-name: move-frames-21;
    -webkit-animation-duration: 36244ms;
    animation-duration: 36244ms;
    -webkit-animation-delay: 32039ms;
    animation-delay: 32039ms;
}
@-webkit-keyframes move-frames-21 {
    from {
        transform: translate3d(45vw, 107vh, 0);
    }
    to {
        transform: translate3d(59vw, -119vh, 0);
    }
}
@keyframes move-frames-21 {
    from {
        transform: translate3d(45vw, 107vh, 0);
    }
    to {
        transform: translate3d(59vw, -119vh, 0);
    }
}
.circle-container:nth-child(21) .circle {
    -webkit-animation-delay: 2626ms;
    animation-delay: 2626ms;
}
.circle-container:nth-child(22) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-22;
    animation-name: move-frames-22;
    -webkit-animation-duration: 34478ms;
    animation-duration: 34478ms;
    -webkit-animation-delay: 297ms;
    animation-delay: 297ms;
}
@-webkit-keyframes move-frames-22 {
    from {
        transform: translate3d(3vw, 106vh, 0);
    }
    to {
        transform: translate3d(82vw, -134vh, 0);
    }
}
@keyframes move-frames-22 {
    from {
        transform: translate3d(3vw, 106vh, 0);
    }
    to {
        transform: translate3d(82vw, -134vh, 0);
    }
}
.circle-container:nth-child(22) .circle {
    -webkit-animation-delay: 555ms;
    animation-delay: 555ms;
}
.circle-container:nth-child(23) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-23;
    animation-name: move-frames-23;
    -webkit-animation-duration: 28560ms;
    animation-duration: 28560ms;
    -webkit-animation-delay: 25828ms;
    animation-delay: 25828ms;
}
@-webkit-keyframes move-frames-23 {
    from {
        transform: translate3d(43vw, 107vh, 0);
    }
    to {
        transform: translate3d(38vw, -111vh, 0);
    }
}
@keyframes move-frames-23 {
    from {
        transform: translate3d(43vw, 107vh, 0);
    }
    to {
        transform: translate3d(38vw, -111vh, 0);
    }
}
.circle-container:nth-child(23) .circle {
    -webkit-animation-delay: 1864ms;
    animation-delay: 1864ms;
}
.circle-container:nth-child(24) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-24;
    animation-name: move-frames-24;
    -webkit-animation-duration: 32288ms;
    animation-duration: 32288ms;
    -webkit-animation-delay: 7471ms;
    animation-delay: 7471ms;
}
@-webkit-keyframes move-frames-24 {
    from {
        transform: translate3d(7vw, 107vh, 0);
    }
    to {
        transform: translate3d(99vw, -108vh, 0);
    }
}
@keyframes move-frames-24 {
    from {
        transform: translate3d(7vw, 107vh, 0);
    }
    to {
        transform: translate3d(99vw, -108vh, 0);
    }
}
.circle-container:nth-child(24) .circle {
    -webkit-animation-delay: 1783ms;
    animation-delay: 1783ms;
}
.circle-container:nth-child(25) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-25;
    animation-name: move-frames-25;
    -webkit-animation-duration: 32195ms;
    animation-duration: 32195ms;
    -webkit-animation-delay: 14093ms;
    animation-delay: 14093ms;
}
@-webkit-keyframes move-frames-25 {
    from {
        transform: translate3d(77vw, 105vh, 0);
    }
    to {
        transform: translate3d(96vw, -111vh, 0);
    }
}
@keyframes move-frames-25 {
    from {
        transform: translate3d(77vw, 105vh, 0);
    }
    to {
        transform: translate3d(96vw, -111vh, 0);
    }
}
.circle-container:nth-child(25) .circle {
    -webkit-animation-delay: 2000ms;
    animation-delay: 2000ms;
}
.circle-container:nth-child(26) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-26;
    animation-name: move-frames-26;
    -webkit-animation-duration: 33306ms;
    animation-duration: 33306ms;
    -webkit-animation-delay: 35732ms;
    animation-delay: 35732ms;
}
@-webkit-keyframes move-frames-26 {
    from {
        transform: translate3d(83vw, 102vh, 0);
    }
    to {
        transform: translate3d(18vw, -107vh, 0);
    }
}
@keyframes move-frames-26 {
    from {
        transform: translate3d(83vw, 102vh, 0);
    }
    to {
        transform: translate3d(18vw, -107vh, 0);
    }
}
.circle-container:nth-child(26) .circle {
    -webkit-animation-delay: 2041ms;
    animation-delay: 2041ms;
}
.circle-container:nth-child(27) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-27;
    animation-name: move-frames-27;
    -webkit-animation-duration: 35421ms;
    animation-duration: 35421ms;
    -webkit-animation-delay: 13238ms;
    animation-delay: 13238ms;
}
@-webkit-keyframes move-frames-27 {
    from {
        transform: translate3d(37vw, 107vh, 0);
    }
    to {
        transform: translate3d(76vw, -122vh, 0);
    }
}
@keyframes move-frames-27 {
    from {
        transform: translate3d(37vw, 107vh, 0);
    }
    to {
        transform: translate3d(76vw, -122vh, 0);
    }
}
.circle-container:nth-child(27) .circle {
    -webkit-animation-delay: 1729ms;
    animation-delay: 1729ms;
}
.circle-container:nth-child(28) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-28;
    animation-name: move-frames-28;
    -webkit-animation-duration: 29996ms;
    animation-duration: 29996ms;
    -webkit-animation-delay: 599ms;
    animation-delay: 599ms;
}
@-webkit-keyframes move-frames-28 {
    from {
        transform: translate3d(22vw, 101vh, 0);
    }
    to {
        transform: translate3d(87vw, -105vh, 0);
    }
}
@keyframes move-frames-28 {
    from {
        transform: translate3d(22vw, 101vh, 0);
    }
    to {
        transform: translate3d(87vw, -105vh, 0);
    }
}
.circle-container:nth-child(28) .circle {
    -webkit-animation-delay: 3125ms;
    animation-delay: 3125ms;
}
.circle-container:nth-child(29) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-29;
    animation-name: move-frames-29;
    -webkit-animation-duration: 29377ms;
    animation-duration: 29377ms;
    -webkit-animation-delay: 27707ms;
    animation-delay: 27707ms;
}
@-webkit-keyframes move-frames-29 {
    from {
        transform: translate3d(34vw, 101vh, 0);
    }
    to {
        transform: translate3d(52vw, -102vh, 0);
    }
}
@keyframes move-frames-29 {
    from {
        transform: translate3d(34vw, 101vh, 0);
    }
    to {
        transform: translate3d(52vw, -102vh, 0);
    }
}
.circle-container:nth-child(29) .circle {
    -webkit-animation-delay: 785ms;
    animation-delay: 785ms;
}
.circle-container:nth-child(30) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-30;
    animation-name: move-frames-30;
    -webkit-animation-duration: 30310ms;
    animation-duration: 30310ms;
    -webkit-animation-delay: 7102ms;
    animation-delay: 7102ms;
}
@-webkit-keyframes move-frames-30 {
    from {
        transform: translate3d(71vw, 110vh, 0);
    }
    to {
        transform: translate3d(25vw, -124vh, 0);
    }
}
@keyframes move-frames-30 {
    from {
        transform: translate3d(71vw, 110vh, 0);
    }
    to {
        transform: translate3d(25vw, -124vh, 0);
    }
}
.circle-container:nth-child(30) .circle {
    -webkit-animation-delay: 334ms;
    animation-delay: 334ms;
}
.circle-container:nth-child(31) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-31;
    animation-name: move-frames-31;
    -webkit-animation-duration: 36325ms;
    animation-duration: 36325ms;
    -webkit-animation-delay: 4949ms;
    animation-delay: 4949ms;
}
@-webkit-keyframes move-frames-31 {
    from {
        transform: translate3d(91vw, 102vh, 0);
    }
    to {
        transform: translate3d(15vw, -121vh, 0);
    }
}
@keyframes move-frames-31 {
    from {
        transform: translate3d(91vw, 102vh, 0);
    }
    to {
        transform: translate3d(15vw, -121vh, 0);
    }
}
.circle-container:nth-child(31) .circle {
    -webkit-animation-delay: 929ms;
    animation-delay: 929ms;
}
.circle-container:nth-child(32) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-32;
    animation-name: move-frames-32;
    -webkit-animation-duration: 36391ms;
    animation-duration: 36391ms;
    -webkit-animation-delay: 31566ms;
    animation-delay: 31566ms;
}
@-webkit-keyframes move-frames-32 {
    from {
        transform: translate3d(28vw, 102vh, 0);
    }
    to {
        transform: translate3d(30vw, -121vh, 0);
    }
}
@keyframes move-frames-32 {
    from {
        transform: translate3d(28vw, 102vh, 0);
    }
    to {
        transform: translate3d(30vw, -121vh, 0);
    }
}
.circle-container:nth-child(32) .circle {
    -webkit-animation-delay: 188ms;
    animation-delay: 188ms;
}
.circle-container:nth-child(33) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-33;
    animation-name: move-frames-33;
    -webkit-animation-duration: 34371ms;
    animation-duration: 34371ms;
    -webkit-animation-delay: 24255ms;
    animation-delay: 24255ms;
}
@-webkit-keyframes move-frames-33 {
    from {
        transform: translate3d(1vw, 103vh, 0);
    }
    to {
        transform: translate3d(91vw, -129vh, 0);
    }
}
@keyframes move-frames-33 {
    from {
        transform: translate3d(1vw, 103vh, 0);
    }
    to {
        transform: translate3d(91vw, -129vh, 0);
    }
}
.circle-container:nth-child(33) .circle {
    -webkit-animation-delay: 3318ms;
    animation-delay: 3318ms;
}
.circle-container:nth-child(34) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-34;
    animation-name: move-frames-34;
    -webkit-animation-duration: 31512ms;
    animation-duration: 31512ms;
    -webkit-animation-delay: 31993ms;
    animation-delay: 31993ms;
}
@-webkit-keyframes move-frames-34 {
    from {
        transform: translate3d(38vw, 102vh, 0);
    }
    to {
        transform: translate3d(78vw, -123vh, 0);
    }
}
@keyframes move-frames-34 {
    from {
        transform: translate3d(38vw, 102vh, 0);
    }
    to {
        transform: translate3d(78vw, -123vh, 0);
    }
}
.circle-container:nth-child(34) .circle {
    -webkit-animation-delay: 2342ms;
    animation-delay: 2342ms;
}
.circle-container:nth-child(35) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-35;
    animation-name: move-frames-35;
    -webkit-animation-duration: 36053ms;
    animation-duration: 36053ms;
    -webkit-animation-delay: 5301ms;
    animation-delay: 5301ms;
}
@-webkit-keyframes move-frames-35 {
    from {
        transform: translate3d(36vw, 103vh, 0);
    }
    to {
        transform: translate3d(71vw, -121vh, 0);
    }
}
@keyframes move-frames-35 {
    from {
        transform: translate3d(36vw, 103vh, 0);
    }
    to {
        transform: translate3d(71vw, -121vh, 0);
    }
}
.circle-container:nth-child(35) .circle {
    -webkit-animation-delay: 2074ms;
    animation-delay: 2074ms;
}
.circle-container:nth-child(36) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-36;
    animation-name: move-frames-36;
    -webkit-animation-duration: 35994ms;
    animation-duration: 35994ms;
    -webkit-animation-delay: 29267ms;
    animation-delay: 29267ms;
}
@-webkit-keyframes move-frames-36 {
    from {
        transform: translate3d(30vw, 107vh, 0);
    }
    to {
        transform: translate3d(43vw, -121vh, 0);
    }
}
@keyframes move-frames-36 {
    from {
        transform: translate3d(30vw, 107vh, 0);
    }
    to {
        transform: translate3d(43vw, -121vh, 0);
    }
}
.circle-container:nth-child(36) .circle {
    -webkit-animation-delay: 63ms;
    animation-delay: 63ms;
}
.circle-container:nth-child(37) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-37;
    animation-name: move-frames-37;
    -webkit-animation-duration: 35879ms;
    animation-duration: 35879ms;
    -webkit-animation-delay: 23906ms;
    animation-delay: 23906ms;
}
@-webkit-keyframes move-frames-37 {
    from {
        transform: translate3d(77vw, 102vh, 0);
    }
    to {
        transform: translate3d(45vw, -123vh, 0);
    }
}
@keyframes move-frames-37 {
    from {
        transform: translate3d(77vw, 102vh, 0);
    }
    to {
        transform: translate3d(45vw, -123vh, 0);
    }
}
.circle-container:nth-child(37) .circle {
    -webkit-animation-delay: 1135ms;
    animation-delay: 1135ms;
}
.circle-container:nth-child(38) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-38;
    animation-name: move-frames-38;
    -webkit-animation-duration: 35777ms;
    animation-duration: 35777ms;
    -webkit-animation-delay: 5862ms;
    animation-delay: 5862ms;
}
@-webkit-keyframes move-frames-38 {
    from {
        transform: translate3d(95vw, 102vh, 0);
    }
    to {
        transform: translate3d(96vw, -104vh, 0);
    }
}
@keyframes move-frames-38 {
    from {
        transform: translate3d(95vw, 102vh, 0);
    }
    to {
        transform: translate3d(96vw, -104vh, 0);
    }
}
.circle-container:nth-child(38) .circle {
    -webkit-animation-delay: 1559ms;
    animation-delay: 1559ms;
}
.circle-container:nth-child(39) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-39;
    animation-name: move-frames-39;
    -webkit-animation-duration: 33634ms;
    animation-duration: 33634ms;
    -webkit-animation-delay: 22836ms;
    animation-delay: 22836ms;
}
@-webkit-keyframes move-frames-39 {
    from {
        transform: translate3d(60vw, 105vh, 0);
    }
    to {
        transform: translate3d(77vw, -132vh, 0);
    }
}
@keyframes move-frames-39 {
    from {
        transform: translate3d(60vw, 105vh, 0);
    }
    to {
        transform: translate3d(77vw, -132vh, 0);
    }
}
.circle-container:nth-child(39) .circle {
    -webkit-animation-delay: 3578ms;
    animation-delay: 3578ms;
}
.circle-container:nth-child(40) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-40;
    animation-name: move-frames-40;
    -webkit-animation-duration: 33985ms;
    animation-duration: 33985ms;
    -webkit-animation-delay: 35971ms;
    animation-delay: 35971ms;
}
@-webkit-keyframes move-frames-40 {
    from {
        transform: translate3d(34vw, 107vh, 0);
    }
    to {
        transform: translate3d(15vw, -136vh, 0);
    }
}
@keyframes move-frames-40 {
    from {
        transform: translate3d(34vw, 107vh, 0);
    }
    to {
        transform: translate3d(15vw, -136vh, 0);
    }
}
.circle-container:nth-child(40) .circle {
    -webkit-animation-delay: 331ms;
    animation-delay: 331ms;
}
.circle-container:nth-child(41) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-41;
    animation-name: move-frames-41;
    -webkit-animation-duration: 29836ms;
    animation-duration: 29836ms;
    -webkit-animation-delay: 1520ms;
    animation-delay: 1520ms;
}
@-webkit-keyframes move-frames-41 {
    from {
        transform: translate3d(11vw, 103vh, 0);
    }
    to {
        transform: translate3d(61vw, -109vh, 0);
    }
}
@keyframes move-frames-41 {
    from {
        transform: translate3d(11vw, 103vh, 0);
    }
    to {
        transform: translate3d(61vw, -109vh, 0);
    }
}
.circle-container:nth-child(41) .circle {
    -webkit-animation-delay: 2085ms;
    animation-delay: 2085ms;
}
.circle-container:nth-child(42) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-42;
    animation-name: move-frames-42;
    -webkit-animation-duration: 34079ms;
    animation-duration: 34079ms;
    -webkit-animation-delay: 8916ms;
    animation-delay: 8916ms;
}
@-webkit-keyframes move-frames-42 {
    from {
        transform: translate3d(68vw, 101vh, 0);
    }
    to {
        transform: translate3d(36vw, -118vh, 0);
    }
}
@keyframes move-frames-42 {
    from {
        transform: translate3d(68vw, 101vh, 0);
    }
    to {
        transform: translate3d(36vw, -118vh, 0);
    }
}
.circle-container:nth-child(42) .circle {
    -webkit-animation-delay: 3693ms;
    animation-delay: 3693ms;
}
.circle-container:nth-child(43) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-43;
    animation-name: move-frames-43;
    -webkit-animation-duration: 36583ms;
    animation-duration: 36583ms;
    -webkit-animation-delay: 5951ms;
    animation-delay: 5951ms;
}
@-webkit-keyframes move-frames-43 {
    from {
        transform: translate3d(79vw, 107vh, 0);
    }
    to {
        transform: translate3d(90vw, -134vh, 0);
    }
}
@keyframes move-frames-43 {
    from {
        transform: translate3d(79vw, 107vh, 0);
    }
    to {
        transform: translate3d(90vw, -134vh, 0);
    }
}
.circle-container:nth-child(43) .circle {
    -webkit-animation-delay: 2875ms;
    animation-delay: 2875ms;
}
.circle-container:nth-child(44) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-44;
    animation-name: move-frames-44;
    -webkit-animation-duration: 32846ms;
    animation-duration: 32846ms;
    -webkit-animation-delay: 1809ms;
    animation-delay: 1809ms;
}
@-webkit-keyframes move-frames-44 {
    from {
        transform: translate3d(92vw, 107vh, 0);
    }
    to {
        transform: translate3d(55vw, -121vh, 0);
    }
}
@keyframes move-frames-44 {
    from {
        transform: translate3d(92vw, 107vh, 0);
    }
    to {
        transform: translate3d(55vw, -121vh, 0);
    }
}
.circle-container:nth-child(44) .circle {
    -webkit-animation-delay: 3584ms;
    animation-delay: 3584ms;
}
.circle-container:nth-child(45) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-45;
    animation-name: move-frames-45;
    -webkit-animation-duration: 31899ms;
    animation-duration: 31899ms;
    -webkit-animation-delay: 6445ms;
    animation-delay: 6445ms;
}
@-webkit-keyframes move-frames-45 {
    from {
        transform: translate3d(2vw, 109vh, 0);
    }
    to {
        transform: translate3d(41vw, -135vh, 0);
    }
}
@keyframes move-frames-45 {
    from {
        transform: translate3d(2vw, 109vh, 0);
    }
    to {
        transform: translate3d(41vw, -135vh, 0);
    }
}
.circle-container:nth-child(45) .circle {
    -webkit-animation-delay: 2309ms;
    animation-delay: 2309ms;
}
.circle-container:nth-child(46) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-46;
    animation-name: move-frames-46;
    -webkit-animation-duration: 28920ms;
    animation-duration: 28920ms;
    -webkit-animation-delay: 4376ms;
    animation-delay: 4376ms;
}
@-webkit-keyframes move-frames-46 {
    from {
        transform: translate3d(14vw, 105vh, 0);
    }
    to {
        transform: translate3d(9vw, -108vh, 0);
    }
}
@keyframes move-frames-46 {
    from {
        transform: translate3d(14vw, 105vh, 0);
    }
    to {
        transform: translate3d(9vw, -108vh, 0);
    }
}
.circle-container:nth-child(46) .circle {
    -webkit-animation-delay: 905ms;
    animation-delay: 905ms;
}
.circle-container:nth-child(47) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-47;
    animation-name: move-frames-47;
    -webkit-animation-duration: 33174ms;
    animation-duration: 33174ms;
    -webkit-animation-delay: 28443ms;
    animation-delay: 28443ms;
}
@-webkit-keyframes move-frames-47 {
    from {
        transform: translate3d(74vw, 106vh, 0);
    }
    to {
        transform: translate3d(47vw, -111vh, 0);
    }
}
@keyframes move-frames-47 {
    from {
        transform: translate3d(74vw, 106vh, 0);
    }
    to {
        transform: translate3d(47vw, -111vh, 0);
    }
}
.circle-container:nth-child(47) .circle {
    -webkit-animation-delay: 1410ms;
    animation-delay: 1410ms;
}
.circle-container:nth-child(48) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-48;
    animation-name: move-frames-48;
    -webkit-animation-duration: 34380ms;
    animation-duration: 34380ms;
    -webkit-animation-delay: 20118ms;
    animation-delay: 20118ms;
}
@-webkit-keyframes move-frames-48 {
    from {
        transform: translate3d(84vw, 110vh, 0);
    }
    to {
        transform: translate3d(76vw, -133vh, 0);
    }
}
@keyframes move-frames-48 {
    from {
        transform: translate3d(84vw, 110vh, 0);
    }
    to {
        transform: translate3d(76vw, -133vh, 0);
    }
}
.circle-container:nth-child(48) .circle {
    -webkit-animation-delay: 3968ms;
    animation-delay: 3968ms;
}
.circle-container:nth-child(49) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-49;
    animation-name: move-frames-49;
    -webkit-animation-duration: 31478ms;
    animation-duration: 31478ms;
    -webkit-animation-delay: 26255ms;
    animation-delay: 26255ms;
}
@-webkit-keyframes move-frames-49 {
    from {
        transform: translate3d(71vw, 105vh, 0);
    }
    to {
        transform: translate3d(31vw, -128vh, 0);
    }
}
@keyframes move-frames-49 {
    from {
        transform: translate3d(71vw, 105vh, 0);
    }
    to {
        transform: translate3d(31vw, -128vh, 0);
    }
}
.circle-container:nth-child(49) .circle {
    -webkit-animation-delay: 1792ms;
    animation-delay: 1792ms;
}
.circle-container:nth-child(50) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-50;
    animation-name: move-frames-50;
    -webkit-animation-duration: 29858ms;
    animation-duration: 29858ms;
    -webkit-animation-delay: 1658ms;
    animation-delay: 1658ms;
}
@-webkit-keyframes move-frames-50 {
    from {
        transform: translate3d(79vw, 105vh, 0);
    }
    to {
        transform: translate3d(77vw, -107vh, 0);
    }
}
@keyframes move-frames-50 {
    from {
        transform: translate3d(79vw, 105vh, 0);
    }
    to {
        transform: translate3d(77vw, -107vh, 0);
    }
}
.circle-container:nth-child(50) .circle {
    -webkit-animation-delay: 3475ms;
    animation-delay: 3475ms;
}
.circle-container:nth-child(51) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-51;
    animation-name: move-frames-51;
    -webkit-animation-duration: 31503ms;
    animation-duration: 31503ms;
    -webkit-animation-delay: 14202ms;
    animation-delay: 14202ms;
}
@-webkit-keyframes move-frames-51 {
    from {
        transform: translate3d(67vw, 103vh, 0);
    }
    to {
        transform: translate3d(8vw, -108vh, 0);
    }
}
@keyframes move-frames-51 {
    from {
        transform: translate3d(67vw, 103vh, 0);
    }
    to {
        transform: translate3d(8vw, -108vh, 0);
    }
}
.circle-container:nth-child(51) .circle {
    -webkit-animation-delay: 342ms;
    animation-delay: 342ms;
}
.circle-container:nth-child(52) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-52;
    animation-name: move-frames-52;
    -webkit-animation-duration: 31812ms;
    animation-duration: 31812ms;
    -webkit-animation-delay: 21687ms;
    animation-delay: 21687ms;
}
@-webkit-keyframes move-frames-52 {
    from {
        transform: translate3d(20vw, 102vh, 0);
    }
    to {
        transform: translate3d(8vw, -127vh, 0);
    }
}
@keyframes move-frames-52 {
    from {
        transform: translate3d(20vw, 102vh, 0);
    }
    to {
        transform: translate3d(8vw, -127vh, 0);
    }
}
.circle-container:nth-child(52) .circle {
    -webkit-animation-delay: 2089ms;
    animation-delay: 2089ms;
}
.circle-container:nth-child(53) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-53;
    animation-name: move-frames-53;
    -webkit-animation-duration: 29912ms;
    animation-duration: 29912ms;
    -webkit-animation-delay: 21718ms;
    animation-delay: 21718ms;
}
@-webkit-keyframes move-frames-53 {
    from {
        transform: translate3d(65vw, 106vh, 0);
    }
    to {
        transform: translate3d(27vw, -112vh, 0);
    }
}
@keyframes move-frames-53 {
    from {
        transform: translate3d(65vw, 106vh, 0);
    }
    to {
        transform: translate3d(27vw, -112vh, 0);
    }
}
.circle-container:nth-child(53) .circle {
    -webkit-animation-delay: 3149ms;
    animation-delay: 3149ms;
}
.circle-container:nth-child(54) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-54;
    animation-name: move-frames-54;
    -webkit-animation-duration: 36348ms;
    animation-duration: 36348ms;
    -webkit-animation-delay: 13805ms;
    animation-delay: 13805ms;
}
@-webkit-keyframes move-frames-54 {
    from {
        transform: translate3d(29vw, 106vh, 0);
    }
    to {
        transform: translate3d(8vw, -127vh, 0);
    }
}
@keyframes move-frames-54 {
    from {
        transform: translate3d(29vw, 106vh, 0);
    }
    to {
        transform: translate3d(8vw, -127vh, 0);
    }
}
.circle-container:nth-child(54) .circle {
    -webkit-animation-delay: 1801ms;
    animation-delay: 1801ms;
}
.circle-container:nth-child(55) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-55;
    animation-name: move-frames-55;
    -webkit-animation-duration: 32568ms;
    animation-duration: 32568ms;
    -webkit-animation-delay: 19186ms;
    animation-delay: 19186ms;
}
@-webkit-keyframes move-frames-55 {
    from {
        transform: translate3d(57vw, 109vh, 0);
    }
    to {
        transform: translate3d(73vw, -131vh, 0);
    }
}
@keyframes move-frames-55 {
    from {
        transform: translate3d(57vw, 109vh, 0);
    }
    to {
        transform: translate3d(73vw, -131vh, 0);
    }
}
.circle-container:nth-child(55) .circle {
    -webkit-animation-delay: 3276ms;
    animation-delay: 3276ms;
}
.circle-container:nth-child(56) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-56;
    animation-name: move-frames-56;
    -webkit-animation-duration: 36351ms;
    animation-duration: 36351ms;
    -webkit-animation-delay: 23681ms;
    animation-delay: 23681ms;
}
@-webkit-keyframes move-frames-56 {
    from {
        transform: translate3d(78vw, 109vh, 0);
    }
    to {
        transform: translate3d(83vw, -119vh, 0);
    }
}
@keyframes move-frames-56 {
    from {
        transform: translate3d(78vw, 109vh, 0);
    }
    to {
        transform: translate3d(83vw, -119vh, 0);
    }
}
.circle-container:nth-child(56) .circle {
    -webkit-animation-delay: 923ms;
    animation-delay: 923ms;
}
.circle-container:nth-child(57) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-57;
    animation-name: move-frames-57;
    -webkit-animation-duration: 29652ms;
    animation-duration: 29652ms;
    -webkit-animation-delay: 15978ms;
    animation-delay: 15978ms;
}
@-webkit-keyframes move-frames-57 {
    from {
        transform: translate3d(45vw, 106vh, 0);
    }
    to {
        transform: translate3d(26vw, -136vh, 0);
    }
}
@keyframes move-frames-57 {
    from {
        transform: translate3d(45vw, 106vh, 0);
    }
    to {
        transform: translate3d(26vw, -136vh, 0);
    }
}
.circle-container:nth-child(57) .circle {
    -webkit-animation-delay: 551ms;
    animation-delay: 551ms;
}
.circle-container:nth-child(58) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-58;
    animation-name: move-frames-58;
    -webkit-animation-duration: 32944ms;
    animation-duration: 32944ms;
    -webkit-animation-delay: 31843ms;
    animation-delay: 31843ms;
}
@-webkit-keyframes move-frames-58 {
    from {
        transform: translate3d(4vw, 102vh, 0);
    }
    to {
        transform: translate3d(64vw, -132vh, 0);
    }
}
@keyframes move-frames-58 {
    from {
        transform: translate3d(4vw, 102vh, 0);
    }
    to {
        transform: translate3d(64vw, -132vh, 0);
    }
}
.circle-container:nth-child(58) .circle {
    -webkit-animation-delay: 3166ms;
    animation-delay: 3166ms;
}
.circle-container:nth-child(59) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-59;
    animation-name: move-frames-59;
    -webkit-animation-duration: 29699ms;
    animation-duration: 29699ms;
    -webkit-animation-delay: 29339ms;
    animation-delay: 29339ms;
}
@-webkit-keyframes move-frames-59 {
    from {
        transform: translate3d(80vw, 108vh, 0);
    }
    to {
        transform: translate3d(79vw, -128vh, 0);
    }
}
@keyframes move-frames-59 {
    from {
        transform: translate3d(80vw, 108vh, 0);
    }
    to {
        transform: translate3d(79vw, -128vh, 0);
    }
}
.circle-container:nth-child(59) .circle {
    -webkit-animation-delay: 3333ms;
    animation-delay: 3333ms;
}
.circle-container:nth-child(60) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-60;
    animation-name: move-frames-60;
    -webkit-animation-duration: 36299ms;
    animation-duration: 36299ms;
    -webkit-animation-delay: 15727ms;
    animation-delay: 15727ms;
}
@-webkit-keyframes move-frames-60 {
    from {
        transform: translate3d(31vw, 104vh, 0);
    }
    to {
        transform: translate3d(89vw, -134vh, 0);
    }
}
@keyframes move-frames-60 {
    from {
        transform: translate3d(31vw, 104vh, 0);
    }
    to {
        transform: translate3d(89vw, -134vh, 0);
    }
}
.circle-container:nth-child(60) .circle {
    -webkit-animation-delay: 2284ms;
    animation-delay: 2284ms;
}
.circle-container:nth-child(61) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-61;
    animation-name: move-frames-61;
    -webkit-animation-duration: 28677ms;
    animation-duration: 28677ms;
    -webkit-animation-delay: 32814ms;
    animation-delay: 32814ms;
}
@-webkit-keyframes move-frames-61 {
    from {
        transform: translate3d(69vw, 109vh, 0);
    }
    to {
        transform: translate3d(1vw, -138vh, 0);
    }
}
@keyframes move-frames-61 {
    from {
        transform: translate3d(69vw, 109vh, 0);
    }
    to {
        transform: translate3d(1vw, -138vh, 0);
    }
}
.circle-container:nth-child(61) .circle {
    -webkit-animation-delay: 903ms;
    animation-delay: 903ms;
}
.circle-container:nth-child(62) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-62;
    animation-name: move-frames-62;
    -webkit-animation-duration: 32747ms;
    animation-duration: 32747ms;
    -webkit-animation-delay: 16938ms;
    animation-delay: 16938ms;
}
@-webkit-keyframes move-frames-62 {
    from {
        transform: translate3d(1vw, 108vh, 0);
    }
    to {
        transform: translate3d(47vw, -128vh, 0);
    }
}
@keyframes move-frames-62 {
    from {
        transform: translate3d(1vw, 108vh, 0);
    }
    to {
        transform: translate3d(47vw, -128vh, 0);
    }
}
.circle-container:nth-child(62) .circle {
    -webkit-animation-delay: 2228ms;
    animation-delay: 2228ms;
}
.circle-container:nth-child(63) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-63;
    animation-name: move-frames-63;
    -webkit-animation-duration: 31972ms;
    animation-duration: 31972ms;
    -webkit-animation-delay: 10717ms;
    animation-delay: 10717ms;
}
@-webkit-keyframes move-frames-63 {
    from {
        transform: translate3d(14vw, 102vh, 0);
    }
    to {
        transform: translate3d(68vw, -111vh, 0);
    }
}
@keyframes move-frames-63 {
    from {
        transform: translate3d(14vw, 102vh, 0);
    }
    to {
        transform: translate3d(68vw, -111vh, 0);
    }
}
.circle-container:nth-child(63) .circle {
    -webkit-animation-delay: 3709ms;
    animation-delay: 3709ms;
}
.circle-container:nth-child(64) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-64;
    animation-name: move-frames-64;
    -webkit-animation-duration: 30565ms;
    animation-duration: 30565ms;
    -webkit-animation-delay: 9165ms;
    animation-delay: 9165ms;
}
@-webkit-keyframes move-frames-64 {
    from {
        transform: translate3d(100vw, 106vh, 0);
    }
    to {
        transform: translate3d(19vw, -109vh, 0);
    }
}
@keyframes move-frames-64 {
    from {
        transform: translate3d(100vw, 106vh, 0);
    }
    to {
        transform: translate3d(19vw, -109vh, 0);
    }
}
.circle-container:nth-child(64) .circle {
    -webkit-animation-delay: 538ms;
    animation-delay: 538ms;
}
.circle-container:nth-child(65) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-65;
    animation-name: move-frames-65;
    -webkit-animation-duration: 35280ms;
    animation-duration: 35280ms;
    -webkit-animation-delay: 21665ms;
    animation-delay: 21665ms;
}
@-webkit-keyframes move-frames-65 {
    from {
        transform: translate3d(65vw, 105vh, 0);
    }
    to {
        transform: translate3d(57vw, -127vh, 0);
    }
}
@keyframes move-frames-65 {
    from {
        transform: translate3d(65vw, 105vh, 0);
    }
    to {
        transform: translate3d(57vw, -127vh, 0);
    }
}
.circle-container:nth-child(65) .circle {
    -webkit-animation-delay: 930ms;
    animation-delay: 930ms;
}
.circle-container:nth-child(66) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-66;
    animation-name: move-frames-66;
    -webkit-animation-duration: 32543ms;
    animation-duration: 32543ms;
    -webkit-animation-delay: 7593ms;
    animation-delay: 7593ms;
}
@-webkit-keyframes move-frames-66 {
    from {
        transform: translate3d(71vw, 102vh, 0);
    }
    to {
        transform: translate3d(90vw, -115vh, 0);
    }
}
@keyframes move-frames-66 {
    from {
        transform: translate3d(71vw, 102vh, 0);
    }
    to {
        transform: translate3d(90vw, -115vh, 0);
    }
}
.circle-container:nth-child(66) .circle {
    -webkit-animation-delay: 1651ms;
    animation-delay: 1651ms;
}
.circle-container:nth-child(67) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-67;
    animation-name: move-frames-67;
    -webkit-animation-duration: 30993ms;
    animation-duration: 30993ms;
    -webkit-animation-delay: 23703ms;
    animation-delay: 23703ms;
}
@-webkit-keyframes move-frames-67 {
    from {
        transform: translate3d(50vw, 107vh, 0);
    }
    to {
        transform: translate3d(1vw, -135vh, 0);
    }
}
@keyframes move-frames-67 {
    from {
        transform: translate3d(50vw, 107vh, 0);
    }
    to {
        transform: translate3d(1vw, -135vh, 0);
    }
}
.circle-container:nth-child(67) .circle {
    -webkit-animation-delay: 2843ms;
    animation-delay: 2843ms;
}
.circle-container:nth-child(68) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-68;
    animation-name: move-frames-68;
    -webkit-animation-duration: 36061ms;
    animation-duration: 36061ms;
    -webkit-animation-delay: 5113ms;
    animation-delay: 5113ms;
}
@-webkit-keyframes move-frames-68 {
    from {
        transform: translate3d(13vw, 104vh, 0);
    }
    to {
        transform: translate3d(32vw, -130vh, 0);
    }
}
@keyframes move-frames-68 {
    from {
        transform: translate3d(13vw, 104vh, 0);
    }
    to {
        transform: translate3d(32vw, -130vh, 0);
    }
}
.circle-container:nth-child(68) .circle {
    -webkit-animation-delay: 247ms;
    animation-delay: 247ms;
}
.circle-container:nth-child(69) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-69;
    animation-name: move-frames-69;
    -webkit-animation-duration: 36110ms;
    animation-duration: 36110ms;
    -webkit-animation-delay: 35831ms;
    animation-delay: 35831ms;
}
@-webkit-keyframes move-frames-69 {
    from {
        transform: translate3d(59vw, 102vh, 0);
    }
    to {
        transform: translate3d(14vw, -109vh, 0);
    }
}
@keyframes move-frames-69 {
    from {
        transform: translate3d(59vw, 102vh, 0);
    }
    to {
        transform: translate3d(14vw, -109vh, 0);
    }
}
.circle-container:nth-child(69) .circle {
    -webkit-animation-delay: 863ms;
    animation-delay: 863ms;
}
.circle-container:nth-child(70) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-70;
    animation-name: move-frames-70;
    -webkit-animation-duration: 28213ms;
    animation-duration: 28213ms;
    -webkit-animation-delay: 18983ms;
    animation-delay: 18983ms;
}
@-webkit-keyframes move-frames-70 {
    from {
        transform: translate3d(66vw, 108vh, 0);
    }
    to {
        transform: translate3d(14vw, -126vh, 0);
    }
}
@keyframes move-frames-70 {
    from {
        transform: translate3d(66vw, 108vh, 0);
    }
    to {
        transform: translate3d(14vw, -126vh, 0);
    }
}
.circle-container:nth-child(70) .circle {
    -webkit-animation-delay: 699ms;
    animation-delay: 699ms;
}
.circle-container:nth-child(71) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-71;
    animation-name: move-frames-71;
    -webkit-animation-duration: 36543ms;
    animation-duration: 36543ms;
    -webkit-animation-delay: 30256ms;
    animation-delay: 30256ms;
}
@-webkit-keyframes move-frames-71 {
    from {
        transform: translate3d(18vw, 108vh, 0);
    }
    to {
        transform: translate3d(80vw, -109vh, 0);
    }
}
@keyframes move-frames-71 {
    from {
        transform: translate3d(18vw, 108vh, 0);
    }
    to {
        transform: translate3d(80vw, -109vh, 0);
    }
}
.circle-container:nth-child(71) .circle {
    -webkit-animation-delay: 3868ms;
    animation-delay: 3868ms;
}
.circle-container:nth-child(72) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-72;
    animation-name: move-frames-72;
    -webkit-animation-duration: 36524ms;
    animation-duration: 36524ms;
    -webkit-animation-delay: 27355ms;
    animation-delay: 27355ms;
}
@-webkit-keyframes move-frames-72 {
    from {
        transform: translate3d(7vw, 102vh, 0);
    }
    to {
        transform: translate3d(61vw, -122vh, 0);
    }
}
@keyframes move-frames-72 {
    from {
        transform: translate3d(7vw, 102vh, 0);
    }
    to {
        transform: translate3d(61vw, -122vh, 0);
    }
}
.circle-container:nth-child(72) .circle {
    -webkit-animation-delay: 1525ms;
    animation-delay: 1525ms;
}
.circle-container:nth-child(73) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-73;
    animation-name: move-frames-73;
    -webkit-animation-duration: 36630ms;
    animation-duration: 36630ms;
    -webkit-animation-delay: 20837ms;
    animation-delay: 20837ms;
}
@-webkit-keyframes move-frames-73 {
    from {
        transform: translate3d(40vw, 108vh, 0);
    }
    to {
        transform: translate3d(62vw, -111vh, 0);
    }
}
@keyframes move-frames-73 {
    from {
        transform: translate3d(40vw, 108vh, 0);
    }
    to {
        transform: translate3d(62vw, -111vh, 0);
    }
}
.circle-container:nth-child(73) .circle {
    -webkit-animation-delay: 1406ms;
    animation-delay: 1406ms;
}
.circle-container:nth-child(74) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-74;
    animation-name: move-frames-74;
    -webkit-animation-duration: 34451ms;
    animation-duration: 34451ms;
    -webkit-animation-delay: 36414ms;
    animation-delay: 36414ms;
}
@-webkit-keyframes move-frames-74 {
    from {
        transform: translate3d(60vw, 106vh, 0);
    }
    to {
        transform: translate3d(15vw, -117vh, 0);
    }
}
@keyframes move-frames-74 {
    from {
        transform: translate3d(60vw, 106vh, 0);
    }
    to {
        transform: translate3d(15vw, -117vh, 0);
    }
}
.circle-container:nth-child(74) .circle {
    -webkit-animation-delay: 2233ms;
    animation-delay: 2233ms;
}
.circle-container:nth-child(75) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-75;
    animation-name: move-frames-75;
    -webkit-animation-duration: 28261ms;
    animation-duration: 28261ms;
    -webkit-animation-delay: 6737ms;
    animation-delay: 6737ms;
}
@-webkit-keyframes move-frames-75 {
    from {
        transform: translate3d(29vw, 108vh, 0);
    }
    to {
        transform: translate3d(14vw, -137vh, 0);
    }
}
@keyframes move-frames-75 {
    from {
        transform: translate3d(29vw, 108vh, 0);
    }
    to {
        transform: translate3d(14vw, -137vh, 0);
    }
}
.circle-container:nth-child(75) .circle {
    -webkit-animation-delay: 1760ms;
    animation-delay: 1760ms;
}
.circle-container:nth-child(76) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-76;
    animation-name: move-frames-76;
    -webkit-animation-duration: 30604ms;
    animation-duration: 30604ms;
    -webkit-animation-delay: 3100ms;
    animation-delay: 3100ms;
}
@-webkit-keyframes move-frames-76 {
    from {
        transform: translate3d(86vw, 110vh, 0);
    }
    to {
        transform: translate3d(88vw, -127vh, 0);
    }
}
@keyframes move-frames-76 {
    from {
        transform: translate3d(86vw, 110vh, 0);
    }
    to {
        transform: translate3d(88vw, -127vh, 0);
    }
}
.circle-container:nth-child(76) .circle {
    -webkit-animation-delay: 906ms;
    animation-delay: 906ms;
}
.circle-container:nth-child(77) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-77;
    animation-name: move-frames-77;
    -webkit-animation-duration: 34689ms;
    animation-duration: 34689ms;
    -webkit-animation-delay: 2925ms;
    animation-delay: 2925ms;
}
@-webkit-keyframes move-frames-77 {
    from {
        transform: translate3d(34vw, 104vh, 0);
    }
    to {
        transform: translate3d(47vw, -107vh, 0);
    }
}
@keyframes move-frames-77 {
    from {
        transform: translate3d(34vw, 104vh, 0);
    }
    to {
        transform: translate3d(47vw, -107vh, 0);
    }
}
.circle-container:nth-child(77) .circle {
    -webkit-animation-delay: 3255ms;
    animation-delay: 3255ms;
}
.circle-container:nth-child(78) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-78;
    animation-name: move-frames-78;
    -webkit-animation-duration: 32992ms;
    animation-duration: 32992ms;
    -webkit-animation-delay: 2828ms;
    animation-delay: 2828ms;
}
@-webkit-keyframes move-frames-78 {
    from {
        transform: translate3d(40vw, 107vh, 0);
    }
    to {
        transform: translate3d(69vw, -122vh, 0);
    }
}
@keyframes move-frames-78 {
    from {
        transform: translate3d(40vw, 107vh, 0);
    }
    to {
        transform: translate3d(69vw, -122vh, 0);
    }
}
.circle-container:nth-child(78) .circle {
    -webkit-animation-delay: 3247ms;
    animation-delay: 3247ms;
}
.circle-container:nth-child(79) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-79;
    animation-name: move-frames-79;
    -webkit-animation-duration: 34398ms;
    animation-duration: 34398ms;
    -webkit-animation-delay: 4812ms;
    animation-delay: 4812ms;
}
@-webkit-keyframes move-frames-79 {
    from {
        transform: translate3d(53vw, 101vh, 0);
    }
    to {
        transform: translate3d(83vw, -104vh, 0);
    }
}
@keyframes move-frames-79 {
    from {
        transform: translate3d(53vw, 101vh, 0);
    }
    to {
        transform: translate3d(83vw, -104vh, 0);
    }
}
.circle-container:nth-child(79) .circle {
    -webkit-animation-delay: 3455ms;
    animation-delay: 3455ms;
}
.circle-container:nth-child(80) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-80;
    animation-name: move-frames-80;
    -webkit-animation-duration: 36102ms;
    animation-duration: 36102ms;
    -webkit-animation-delay: 12872ms;
    animation-delay: 12872ms;
}
@-webkit-keyframes move-frames-80 {
    from {
        transform: translate3d(70vw, 105vh, 0);
    }
    to {
        transform: translate3d(35vw, -109vh, 0);
    }
}
@keyframes move-frames-80 {
    from {
        transform: translate3d(70vw, 105vh, 0);
    }
    to {
        transform: translate3d(35vw, -109vh, 0);
    }
}
.circle-container:nth-child(80) .circle {
    -webkit-animation-delay: 1215ms;
    animation-delay: 1215ms;
}
.circle-container:nth-child(81) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-81;
    animation-name: move-frames-81;
    -webkit-animation-duration: 29934ms;
    animation-duration: 29934ms;
    -webkit-animation-delay: 4537ms;
    animation-delay: 4537ms;
}
@-webkit-keyframes move-frames-81 {
    from {
        transform: translate3d(43vw, 105vh, 0);
    }
    to {
        transform: translate3d(75vw, -131vh, 0);
    }
}
@keyframes move-frames-81 {
    from {
        transform: translate3d(43vw, 105vh, 0);
    }
    to {
        transform: translate3d(75vw, -131vh, 0);
    }
}
.circle-container:nth-child(81) .circle {
    -webkit-animation-delay: 115ms;
    animation-delay: 115ms;
}
.circle-container:nth-child(82) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-82;
    animation-name: move-frames-82;
    -webkit-animation-duration: 33914ms;
    animation-duration: 33914ms;
    -webkit-animation-delay: 18446ms;
    animation-delay: 18446ms;
}
@-webkit-keyframes move-frames-82 {
    from {
        transform: translate3d(20vw, 103vh, 0);
    }
    to {
        transform: translate3d(13vw, -131vh, 0);
    }
}
@keyframes move-frames-82 {
    from {
        transform: translate3d(20vw, 103vh, 0);
    }
    to {
        transform: translate3d(13vw, -131vh, 0);
    }
}
.circle-container:nth-child(82) .circle {
    -webkit-animation-delay: 2034ms;
    animation-delay: 2034ms;
}
.circle-container:nth-child(83) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-83;
    animation-name: move-frames-83;
    -webkit-animation-duration: 33281ms;
    animation-duration: 33281ms;
    -webkit-animation-delay: 15957ms;
    animation-delay: 15957ms;
}
@-webkit-keyframes move-frames-83 {
    from {
        transform: translate3d(21vw, 110vh, 0);
    }
    to {
        transform: translate3d(81vw, -123vh, 0);
    }
}
@keyframes move-frames-83 {
    from {
        transform: translate3d(21vw, 110vh, 0);
    }
    to {
        transform: translate3d(81vw, -123vh, 0);
    }
}
.circle-container:nth-child(83) .circle {
    -webkit-animation-delay: 3264ms;
    animation-delay: 3264ms;
}
.circle-container:nth-child(84) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-84;
    animation-name: move-frames-84;
    -webkit-animation-duration: 36035ms;
    animation-duration: 36035ms;
    -webkit-animation-delay: 5153ms;
    animation-delay: 5153ms;
}
@-webkit-keyframes move-frames-84 {
    from {
        transform: translate3d(26vw, 106vh, 0);
    }
    to {
        transform: translate3d(32vw, -121vh, 0);
    }
}
@keyframes move-frames-84 {
    from {
        transform: translate3d(26vw, 106vh, 0);
    }
    to {
        transform: translate3d(32vw, -121vh, 0);
    }
}
.circle-container:nth-child(84) .circle {
    -webkit-animation-delay: 1473ms;
    animation-delay: 1473ms;
}
.circle-container:nth-child(85) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-85;
    animation-name: move-frames-85;
    -webkit-animation-duration: 36667ms;
    animation-duration: 36667ms;
    -webkit-animation-delay: 6998ms;
    animation-delay: 6998ms;
}
@-webkit-keyframes move-frames-85 {
    from {
        transform: translate3d(34vw, 110vh, 0);
    }
    to {
        transform: translate3d(85vw, -126vh, 0);
    }
}
@keyframes move-frames-85 {
    from {
        transform: translate3d(34vw, 110vh, 0);
    }
    to {
        transform: translate3d(85vw, -126vh, 0);
    }
}
.circle-container:nth-child(85) .circle {
    -webkit-animation-delay: 3971ms;
    animation-delay: 3971ms;
}
.circle-container:nth-child(86) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-86;
    animation-name: move-frames-86;
    -webkit-animation-duration: 35063ms;
    animation-duration: 35063ms;
    -webkit-animation-delay: 2754ms;
    animation-delay: 2754ms;
}
@-webkit-keyframes move-frames-86 {
    from {
        transform: translate3d(85vw, 105vh, 0);
    }
    to {
        transform: translate3d(30vw, -110vh, 0);
    }
}
@keyframes move-frames-86 {
    from {
        transform: translate3d(85vw, 105vh, 0);
    }
    to {
        transform: translate3d(30vw, -110vh, 0);
    }
}
.circle-container:nth-child(86) .circle {
    -webkit-animation-delay: 1080ms;
    animation-delay: 1080ms;
}
.circle-container:nth-child(87) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-87;
    animation-name: move-frames-87;
    -webkit-animation-duration: 36628ms;
    animation-duration: 36628ms;
    -webkit-animation-delay: 15145ms;
    animation-delay: 15145ms;
}
@-webkit-keyframes move-frames-87 {
    from {
        transform: translate3d(64vw, 106vh, 0);
    }
    to {
        transform: translate3d(65vw, -128vh, 0);
    }
}
@keyframes move-frames-87 {
    from {
        transform: translate3d(64vw, 106vh, 0);
    }
    to {
        transform: translate3d(65vw, -128vh, 0);
    }
}
.circle-container:nth-child(87) .circle {
    -webkit-animation-delay: 1630ms;
    animation-delay: 1630ms;
}
.circle-container:nth-child(88) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-88;
    animation-name: move-frames-88;
    -webkit-animation-duration: 31492ms;
    animation-duration: 31492ms;
    -webkit-animation-delay: 19911ms;
    animation-delay: 19911ms;
}
@-webkit-keyframes move-frames-88 {
    from {
        transform: translate3d(9vw, 108vh, 0);
    }
    to {
        transform: translate3d(60vw, -127vh, 0);
    }
}
@keyframes move-frames-88 {
    from {
        transform: translate3d(9vw, 108vh, 0);
    }
    to {
        transform: translate3d(60vw, -127vh, 0);
    }
}
.circle-container:nth-child(88) .circle {
    -webkit-animation-delay: 1855ms;
    animation-delay: 1855ms;
}
.circle-container:nth-child(89) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-89;
    animation-name: move-frames-89;
    -webkit-animation-duration: 35570ms;
    animation-duration: 35570ms;
    -webkit-animation-delay: 35202ms;
    animation-delay: 35202ms;
}
@-webkit-keyframes move-frames-89 {
    from {
        transform: translate3d(92vw, 103vh, 0);
    }
    to {
        transform: translate3d(77vw, -107vh, 0);
    }
}
@keyframes move-frames-89 {
    from {
        transform: translate3d(92vw, 103vh, 0);
    }
    to {
        transform: translate3d(77vw, -107vh, 0);
    }
}
.circle-container:nth-child(89) .circle {
    -webkit-animation-delay: 3504ms;
    animation-delay: 3504ms;
}
.circle-container:nth-child(90) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-90;
    animation-name: move-frames-90;
    -webkit-animation-duration: 33167ms;
    animation-duration: 33167ms;
    -webkit-animation-delay: 23085ms;
    animation-delay: 23085ms;
}
@-webkit-keyframes move-frames-90 {
    from {
        transform: translate3d(66vw, 102vh, 0);
    }
    to {
        transform: translate3d(77vw, -111vh, 0);
    }
}
@keyframes move-frames-90 {
    from {
        transform: translate3d(66vw, 102vh, 0);
    }
    to {
        transform: translate3d(77vw, -111vh, 0);
    }
}
.circle-container:nth-child(90) .circle {
    -webkit-animation-delay: 1726ms;
    animation-delay: 1726ms;
}
.circle-container:nth-child(91) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-91;
    animation-name: move-frames-91;
    -webkit-animation-duration: 36708ms;
    animation-duration: 36708ms;
    -webkit-animation-delay: 1042ms;
    animation-delay: 1042ms;
}
@-webkit-keyframes move-frames-91 {
    from {
        transform: translate3d(49vw, 101vh, 0);
    }
    to {
        transform: translate3d(35vw, -111vh, 0);
    }
}
@keyframes move-frames-91 {
    from {
        transform: translate3d(49vw, 101vh, 0);
    }
    to {
        transform: translate3d(35vw, -111vh, 0);
    }
}
.circle-container:nth-child(91) .circle {
    -webkit-animation-delay: 1577ms;
    animation-delay: 1577ms;
}
.circle-container:nth-child(92) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-92;
    animation-name: move-frames-92;
    -webkit-animation-duration: 35479ms;
    animation-duration: 35479ms;
    -webkit-animation-delay: 7825ms;
    animation-delay: 7825ms;
}
@-webkit-keyframes move-frames-92 {
    from {
        transform: translate3d(58vw, 104vh, 0);
    }
    to {
        transform: translate3d(41vw, -107vh, 0);
    }
}
@keyframes move-frames-92 {
    from {
        transform: translate3d(58vw, 104vh, 0);
    }
    to {
        transform: translate3d(41vw, -107vh, 0);
    }
}
.circle-container:nth-child(92) .circle {
    -webkit-animation-delay: 1400ms;
    animation-delay: 1400ms;
}
.circle-container:nth-child(93) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-93;
    animation-name: move-frames-93;
    -webkit-animation-duration: 31989ms;
    animation-duration: 31989ms;
    -webkit-animation-delay: 34610ms;
    animation-delay: 34610ms;
}
@-webkit-keyframes move-frames-93 {
    from {
        transform: translate3d(89vw, 108vh, 0);
    }
    to {
        transform: translate3d(10vw, -114vh, 0);
    }
}
@keyframes move-frames-93 {
    from {
        transform: translate3d(89vw, 108vh, 0);
    }
    to {
        transform: translate3d(10vw, -114vh, 0);
    }
}
.circle-container:nth-child(93) .circle {
    -webkit-animation-delay: 2264ms;
    animation-delay: 2264ms;
}
.circle-container:nth-child(94) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-94;
    animation-name: move-frames-94;
    -webkit-animation-duration: 28254ms;
    animation-duration: 28254ms;
    -webkit-animation-delay: 22410ms;
    animation-delay: 22410ms;
}
@-webkit-keyframes move-frames-94 {
    from {
        transform: translate3d(82vw, 107vh, 0);
    }
    to {
        transform: translate3d(38vw, -133vh, 0);
    }
}
@keyframes move-frames-94 {
    from {
        transform: translate3d(82vw, 107vh, 0);
    }
    to {
        transform: translate3d(38vw, -133vh, 0);
    }
}
.circle-container:nth-child(94) .circle {
    -webkit-animation-delay: 1742ms;
    animation-delay: 1742ms;
}
.circle-container:nth-child(95) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-95;
    animation-name: move-frames-95;
    -webkit-animation-duration: 29353ms;
    animation-duration: 29353ms;
    -webkit-animation-delay: 32360ms;
    animation-delay: 32360ms;
}
@-webkit-keyframes move-frames-95 {
    from {
        transform: translate3d(69vw, 105vh, 0);
    }
    to {
        transform: translate3d(19vw, -125vh, 0);
    }
}
@keyframes move-frames-95 {
    from {
        transform: translate3d(69vw, 105vh, 0);
    }
    to {
        transform: translate3d(19vw, -125vh, 0);
    }
}
.circle-container:nth-child(95) .circle {
    -webkit-animation-delay: 2770ms;
    animation-delay: 2770ms;
}
.circle-container:nth-child(96) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-96;
    animation-name: move-frames-96;
    -webkit-animation-duration: 30221ms;
    animation-duration: 30221ms;
    -webkit-animation-delay: 23442ms;
    animation-delay: 23442ms;
}
@-webkit-keyframes move-frames-96 {
    from {
        transform: translate3d(39vw, 106vh, 0);
    }
    to {
        transform: translate3d(64vw, -130vh, 0);
    }
}
@keyframes move-frames-96 {
    from {
        transform: translate3d(39vw, 106vh, 0);
    }
    to {
        transform: translate3d(64vw, -130vh, 0);
    }
}
.circle-container:nth-child(96) .circle {
    -webkit-animation-delay: 2068ms;
    animation-delay: 2068ms;
}
.circle-container:nth-child(97) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-97;
    animation-name: move-frames-97;
    -webkit-animation-duration: 29456ms;
    animation-duration: 29456ms;
    -webkit-animation-delay: 16539ms;
    animation-delay: 16539ms;
}
@-webkit-keyframes move-frames-97 {
    from {
        transform: translate3d(46vw, 109vh, 0);
    }
    to {
        transform: translate3d(67vw, -128vh, 0);
    }
}
@keyframes move-frames-97 {
    from {
        transform: translate3d(46vw, 109vh, 0);
    }
    to {
        transform: translate3d(67vw, -128vh, 0);
    }
}
.circle-container:nth-child(97) .circle {
    -webkit-animation-delay: 24ms;
    animation-delay: 24ms;
}
.circle-container:nth-child(98) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-98;
    animation-name: move-frames-98;
    -webkit-animation-duration: 34429ms;
    animation-duration: 34429ms;
    -webkit-animation-delay: 16301ms;
    animation-delay: 16301ms;
}
@-webkit-keyframes move-frames-98 {
    from {
        transform: translate3d(8vw, 109vh, 0);
    }
    to {
        transform: translate3d(78vw, -135vh, 0);
    }
}
@keyframes move-frames-98 {
    from {
        transform: translate3d(8vw, 109vh, 0);
    }
    to {
        transform: translate3d(78vw, -135vh, 0);
    }
}
.circle-container:nth-child(98) .circle {
    -webkit-animation-delay: 1034ms;
    animation-delay: 1034ms;
}
.circle-container:nth-child(99) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-99;
    animation-name: move-frames-99;
    -webkit-animation-duration: 36965ms;
    animation-duration: 36965ms;
    -webkit-animation-delay: 35774ms;
    animation-delay: 35774ms;
}
@-webkit-keyframes move-frames-99 {
    from {
        transform: translate3d(7vw, 105vh, 0);
    }
    to {
        transform: translate3d(83vw, -119vh, 0);
    }
}
@keyframes move-frames-99 {
    from {
        transform: translate3d(7vw, 105vh, 0);
    }
    to {
        transform: translate3d(83vw, -119vh, 0);
    }
}
.circle-container:nth-child(99) .circle {
    -webkit-animation-delay: 3211ms;
    animation-delay: 3211ms;
}
.circle-container:nth-child(100) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-100;
    animation-name: move-frames-100;
    -webkit-animation-duration: 33719ms;
    animation-duration: 33719ms;
    -webkit-animation-delay: 31004ms;
    animation-delay: 31004ms;
}
@-webkit-keyframes move-frames-100 {
    from {
        transform: translate3d(69vw, 104vh, 0);
    }
    to {
        transform: translate3d(24vw, -120vh, 0);
    }
}
@keyframes move-frames-100 {
    from {
        transform: translate3d(69vw, 104vh, 0);
    }
    to {
        transform: translate3d(24vw, -120vh, 0);
    }
}
.circle-container:nth-child(100) .circle {
    -webkit-animation-delay: 1807ms;
    animation-delay: 1807ms;
}
.circle-container:nth-child(101) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-101;
    animation-name: move-frames-101;
    -webkit-animation-duration: 36871ms;
    animation-duration: 36871ms;
    -webkit-animation-delay: 19543ms;
    animation-delay: 19543ms;
}
@-webkit-keyframes move-frames-101 {
    from {
        transform: translate3d(93vw, 103vh, 0);
    }
    to {
        transform: translate3d(25vw, -125vh, 0);
    }
}
@keyframes move-frames-101 {
    from {
        transform: translate3d(93vw, 103vh, 0);
    }
    to {
        transform: translate3d(25vw, -125vh, 0);
    }
}
.circle-container:nth-child(101) .circle {
    -webkit-animation-delay: 3448ms;
    animation-delay: 3448ms;
}
.circle-container:nth-child(102) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-102;
    animation-name: move-frames-102;
    -webkit-animation-duration: 34891ms;
    animation-duration: 34891ms;
    -webkit-animation-delay: 36688ms;
    animation-delay: 36688ms;
}
@-webkit-keyframes move-frames-102 {
    from {
        transform: translate3d(14vw, 110vh, 0);
    }
    to {
        transform: translate3d(22vw, -114vh, 0);
    }
}
@keyframes move-frames-102 {
    from {
        transform: translate3d(14vw, 110vh, 0);
    }
    to {
        transform: translate3d(22vw, -114vh, 0);
    }
}
.circle-container:nth-child(102) .circle {
    -webkit-animation-delay: 1544ms;
    animation-delay: 1544ms;
}
.circle-container:nth-child(103) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-103;
    animation-name: move-frames-103;
    -webkit-animation-duration: 29956ms;
    animation-duration: 29956ms;
    -webkit-animation-delay: 13872ms;
    animation-delay: 13872ms;
}
@-webkit-keyframes move-frames-103 {
    from {
        transform: translate3d(93vw, 106vh, 0);
    }
    to {
        transform: translate3d(29vw, -113vh, 0);
    }
}
@keyframes move-frames-103 {
    from {
        transform: translate3d(93vw, 106vh, 0);
    }
    to {
        transform: translate3d(29vw, -113vh, 0);
    }
}
.circle-container:nth-child(103) .circle {
    -webkit-animation-delay: 2857ms;
    animation-delay: 2857ms;
}
.circle-container:nth-child(104) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-104;
    animation-name: move-frames-104;
    -webkit-animation-duration: 32699ms;
    animation-duration: 32699ms;
    -webkit-animation-delay: 19740ms;
    animation-delay: 19740ms;
}
@-webkit-keyframes move-frames-104 {
    from {
        transform: translate3d(18vw, 104vh, 0);
    }
    to {
        transform: translate3d(45vw, -127vh, 0);
    }
}
@keyframes move-frames-104 {
    from {
        transform: translate3d(18vw, 104vh, 0);
    }
    to {
        transform: translate3d(45vw, -127vh, 0);
    }
}
.circle-container:nth-child(104) .circle {
    -webkit-animation-delay: 2335ms;
    animation-delay: 2335ms;
}
.circle-container:nth-child(105) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-105;
    animation-name: move-frames-105;
    -webkit-animation-duration: 35096ms;
    animation-duration: 35096ms;
    -webkit-animation-delay: 1103ms;
    animation-delay: 1103ms;
}
@-webkit-keyframes move-frames-105 {
    from {
        transform: translate3d(47vw, 101vh, 0);
    }
    to {
        transform: translate3d(1vw, -109vh, 0);
    }
}
@keyframes move-frames-105 {
    from {
        transform: translate3d(47vw, 101vh, 0);
    }
    to {
        transform: translate3d(1vw, -109vh, 0);
    }
}
.circle-container:nth-child(105) .circle {
    -webkit-animation-delay: 1628ms;
    animation-delay: 1628ms;
}
.circle-container:nth-child(106) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-106;
    animation-name: move-frames-106;
    -webkit-animation-duration: 29973ms;
    animation-duration: 29973ms;
    -webkit-animation-delay: 12689ms;
    animation-delay: 12689ms;
}
@-webkit-keyframes move-frames-106 {
    from {
        transform: translate3d(35vw, 108vh, 0);
    }
    to {
        transform: translate3d(46vw, -137vh, 0);
    }
}
@keyframes move-frames-106 {
    from {
        transform: translate3d(35vw, 108vh, 0);
    }
    to {
        transform: translate3d(46vw, -137vh, 0);
    }
}
.circle-container:nth-child(106) .circle {
    -webkit-animation-delay: 3708ms;
    animation-delay: 3708ms;
}
.circle-container:nth-child(107) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-107;
    animation-name: move-frames-107;
    -webkit-animation-duration: 35121ms;
    animation-duration: 35121ms;
    -webkit-animation-delay: 31900ms;
    animation-delay: 31900ms;
}
@-webkit-keyframes move-frames-107 {
    from {
        transform: translate3d(54vw, 108vh, 0);
    }
    to {
        transform: translate3d(98vw, -134vh, 0);
    }
}
@keyframes move-frames-107 {
    from {
        transform: translate3d(54vw, 108vh, 0);
    }
    to {
        transform: translate3d(98vw, -134vh, 0);
    }
}
.circle-container:nth-child(107) .circle {
    -webkit-animation-delay: 2246ms;
    animation-delay: 2246ms;
}
.circle-container:nth-child(108) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-108;
    animation-name: move-frames-108;
    -webkit-animation-duration: 34747ms;
    animation-duration: 34747ms;
    -webkit-animation-delay: 15981ms;
    animation-delay: 15981ms;
}
@-webkit-keyframes move-frames-108 {
    from {
        transform: translate3d(64vw, 109vh, 0);
    }
    to {
        transform: translate3d(24vw, -139vh, 0);
    }
}
@keyframes move-frames-108 {
    from {
        transform: translate3d(64vw, 109vh, 0);
    }
    to {
        transform: translate3d(24vw, -139vh, 0);
    }
}
.circle-container:nth-child(108) .circle {
    -webkit-animation-delay: 2961ms;
    animation-delay: 2961ms;
}
.circle-container:nth-child(109) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-109;
    animation-name: move-frames-109;
    -webkit-animation-duration: 31566ms;
    animation-duration: 31566ms;
    -webkit-animation-delay: 21599ms;
    animation-delay: 21599ms;
}
@-webkit-keyframes move-frames-109 {
    from {
        transform: translate3d(61vw, 105vh, 0);
    }
    to {
        transform: translate3d(69vw, -118vh, 0);
    }
}
@keyframes move-frames-109 {
    from {
        transform: translate3d(61vw, 105vh, 0);
    }
    to {
        transform: translate3d(69vw, -118vh, 0);
    }
}
.circle-container:nth-child(109) .circle {
    -webkit-animation-delay: 1332ms;
    animation-delay: 1332ms;
}
.circle-container:nth-child(110) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-110;
    animation-name: move-frames-110;
    -webkit-animation-duration: 36482ms;
    animation-duration: 36482ms;
    -webkit-animation-delay: 30621ms;
    animation-delay: 30621ms;
}
@-webkit-keyframes move-frames-110 {
    from {
        transform: translate3d(92vw, 107vh, 0);
    }
    to {
        transform: translate3d(6vw, -130vh, 0);
    }
}
@keyframes move-frames-110 {
    from {
        transform: translate3d(92vw, 107vh, 0);
    }
    to {
        transform: translate3d(6vw, -130vh, 0);
    }
}
.circle-container:nth-child(110) .circle {
    -webkit-animation-delay: 2942ms;
    animation-delay: 2942ms;
}
.circle-container:nth-child(111) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-111;
    animation-name: move-frames-111;
    -webkit-animation-duration: 29474ms;
    animation-duration: 29474ms;
    -webkit-animation-delay: 35777ms;
    animation-delay: 35777ms;
}
@-webkit-keyframes move-frames-111 {
    from {
        transform: translate3d(6vw, 107vh, 0);
    }
    to {
        transform: translate3d(55vw, -109vh, 0);
    }
}
@keyframes move-frames-111 {
    from {
        transform: translate3d(6vw, 107vh, 0);
    }
    to {
        transform: translate3d(55vw, -109vh, 0);
    }
}
.circle-container:nth-child(111) .circle {
    -webkit-animation-delay: 3280ms;
    animation-delay: 3280ms;
}
.circle-container:nth-child(112) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-112;
    animation-name: move-frames-112;
    -webkit-animation-duration: 30958ms;
    animation-duration: 30958ms;
    -webkit-animation-delay: 9952ms;
    animation-delay: 9952ms;
}
@-webkit-keyframes move-frames-112 {
    from {
        transform: translate3d(62vw, 108vh, 0);
    }
    to {
        transform: translate3d(39vw, -135vh, 0);
    }
}
@keyframes move-frames-112 {
    from {
        transform: translate3d(62vw, 108vh, 0);
    }
    to {
        transform: translate3d(39vw, -135vh, 0);
    }
}
.circle-container:nth-child(112) .circle {
    -webkit-animation-delay: 3022ms;
    animation-delay: 3022ms;
}
.circle-container:nth-child(113) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-113;
    animation-name: move-frames-113;
    -webkit-animation-duration: 36599ms;
    animation-duration: 36599ms;
    -webkit-animation-delay: 3184ms;
    animation-delay: 3184ms;
}
@-webkit-keyframes move-frames-113 {
    from {
        transform: translate3d(90vw, 101vh, 0);
    }
    to {
        transform: translate3d(44vw, -110vh, 0);
    }
}
@keyframes move-frames-113 {
    from {
        transform: translate3d(90vw, 101vh, 0);
    }
    to {
        transform: translate3d(44vw, -110vh, 0);
    }
}
.circle-container:nth-child(113) .circle {
    -webkit-animation-delay: 2107ms;
    animation-delay: 2107ms;
}
.circle-container:nth-child(114) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-114;
    animation-name: move-frames-114;
    -webkit-animation-duration: 36720ms;
    animation-duration: 36720ms;
    -webkit-animation-delay: 218ms;
    animation-delay: 218ms;
}
@-webkit-keyframes move-frames-114 {
    from {
        transform: translate3d(20vw, 101vh, 0);
    }
    to {
        transform: translate3d(60vw, -126vh, 0);
    }
}
@keyframes move-frames-114 {
    from {
        transform: translate3d(20vw, 101vh, 0);
    }
    to {
        transform: translate3d(60vw, -126vh, 0);
    }
}
.circle-container:nth-child(114) .circle {
    -webkit-animation-delay: 317ms;
    animation-delay: 317ms;
}
.circle-container:nth-child(115) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-115;
    animation-name: move-frames-115;
    -webkit-animation-duration: 36831ms;
    animation-duration: 36831ms;
    -webkit-animation-delay: 18548ms;
    animation-delay: 18548ms;
}
@-webkit-keyframes move-frames-115 {
    from {
        transform: translate3d(96vw, 104vh, 0);
    }
    to {
        transform: translate3d(33vw, -121vh, 0);
    }
}
@keyframes move-frames-115 {
    from {
        transform: translate3d(96vw, 104vh, 0);
    }
    to {
        transform: translate3d(33vw, -121vh, 0);
    }
}
.circle-container:nth-child(115) .circle {
    -webkit-animation-delay: 724ms;
    animation-delay: 724ms;
}
.circle-container:nth-child(116) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-116;
    animation-name: move-frames-116;
    -webkit-animation-duration: 34679ms;
    animation-duration: 34679ms;
    -webkit-animation-delay: 13459ms;
    animation-delay: 13459ms;
}
@-webkit-keyframes move-frames-116 {
    from {
        transform: translate3d(53vw, 105vh, 0);
    }
    to {
        transform: translate3d(92vw, -121vh, 0);
    }
}
@keyframes move-frames-116 {
    from {
        transform: translate3d(53vw, 105vh, 0);
    }
    to {
        transform: translate3d(92vw, -121vh, 0);
    }
}
.circle-container:nth-child(116) .circle {
    -webkit-animation-delay: 1789ms;
    animation-delay: 1789ms;
}
.circle-container:nth-child(117) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-117;
    animation-name: move-frames-117;
    -webkit-animation-duration: 32934ms;
    animation-duration: 32934ms;
    -webkit-animation-delay: 29176ms;
    animation-delay: 29176ms;
}
@-webkit-keyframes move-frames-117 {
    from {
        transform: translate3d(42vw, 101vh, 0);
    }
    to {
        transform: translate3d(79vw, -111vh, 0);
    }
}
@keyframes move-frames-117 {
    from {
        transform: translate3d(42vw, 101vh, 0);
    }
    to {
        transform: translate3d(79vw, -111vh, 0);
    }
}
.circle-container:nth-child(117) .circle {
    -webkit-animation-delay: 3235ms;
    animation-delay: 3235ms;
}
.circle-container:nth-child(118) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-118;
    animation-name: move-frames-118;
    -webkit-animation-duration: 28996ms;
    animation-duration: 28996ms;
    -webkit-animation-delay: 10156ms;
    animation-delay: 10156ms;
}
@-webkit-keyframes move-frames-118 {
    from {
        transform: translate3d(64vw, 108vh, 0);
    }
    to {
        transform: translate3d(52vw, -117vh, 0);
    }
}
@keyframes move-frames-118 {
    from {
        transform: translate3d(64vw, 108vh, 0);
    }
    to {
        transform: translate3d(52vw, -117vh, 0);
    }
}
.circle-container:nth-child(118) .circle {
    -webkit-animation-delay: 2860ms;
    animation-delay: 2860ms;
}
.circle-container:nth-child(119) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-119;
    animation-name: move-frames-119;
    -webkit-animation-duration: 36138ms;
    animation-duration: 36138ms;
    -webkit-animation-delay: 3364ms;
    animation-delay: 3364ms;
}
@-webkit-keyframes move-frames-119 {
    from {
        transform: translate3d(17vw, 106vh, 0);
    }
    to {
        transform: translate3d(53vw, -132vh, 0);
    }
}
@keyframes move-frames-119 {
    from {
        transform: translate3d(17vw, 106vh, 0);
    }
    to {
        transform: translate3d(53vw, -132vh, 0);
    }
}
.circle-container:nth-child(119) .circle {
    -webkit-animation-delay: 1518ms;
    animation-delay: 1518ms;
}
.circle-container:nth-child(120) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-120;
    animation-name: move-frames-120;
    -webkit-animation-duration: 35004ms;
    animation-duration: 35004ms;
    -webkit-animation-delay: 36139ms;
    animation-delay: 36139ms;
}
@-webkit-keyframes move-frames-120 {
    from {
        transform: translate3d(60vw, 104vh, 0);
    }
    to {
        transform: translate3d(88vw, -125vh, 0);
    }
}
@keyframes move-frames-120 {
    from {
        transform: translate3d(60vw, 104vh, 0);
    }
    to {
        transform: translate3d(88vw, -125vh, 0);
    }
}
.circle-container:nth-child(120) .circle {
    -webkit-animation-delay: 1370ms;
    animation-delay: 1370ms;
}
.circle-container:nth-child(121) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-121;
    animation-name: move-frames-121;
    -webkit-animation-duration: 33526ms;
    animation-duration: 33526ms;
    -webkit-animation-delay: 25852ms;
    animation-delay: 25852ms;
}
@-webkit-keyframes move-frames-121 {
    from {
        transform: translate3d(65vw, 103vh, 0);
    }
    to {
        transform: translate3d(26vw, -109vh, 0);
    }
}
@keyframes move-frames-121 {
    from {
        transform: translate3d(65vw, 103vh, 0);
    }
    to {
        transform: translate3d(26vw, -109vh, 0);
    }
}
.circle-container:nth-child(121) .circle {
    -webkit-animation-delay: 122ms;
    animation-delay: 122ms;
}
.circle-container:nth-child(122) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-122;
    animation-name: move-frames-122;
    -webkit-animation-duration: 35050ms;
    animation-duration: 35050ms;
    -webkit-animation-delay: 36502ms;
    animation-delay: 36502ms;
}
@-webkit-keyframes move-frames-122 {
    from {
        transform: translate3d(81vw, 102vh, 0);
    }
    to {
        transform: translate3d(90vw, -108vh, 0);
    }
}
@keyframes move-frames-122 {
    from {
        transform: translate3d(81vw, 102vh, 0);
    }
    to {
        transform: translate3d(90vw, -108vh, 0);
    }
}
.circle-container:nth-child(122) .circle {
    -webkit-animation-delay: 3708ms;
    animation-delay: 3708ms;
}
.circle-container:nth-child(123) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-123;
    animation-name: move-frames-123;
    -webkit-animation-duration: 35991ms;
    animation-duration: 35991ms;
    -webkit-animation-delay: 15276ms;
    animation-delay: 15276ms;
}
@-webkit-keyframes move-frames-123 {
    from {
        transform: translate3d(85vw, 107vh, 0);
    }
    to {
        transform: translate3d(71vw, -116vh, 0);
    }
}
@keyframes move-frames-123 {
    from {
        transform: translate3d(85vw, 107vh, 0);
    }
    to {
        transform: translate3d(71vw, -116vh, 0);
    }
}
.circle-container:nth-child(123) .circle {
    -webkit-animation-delay: 2824ms;
    animation-delay: 2824ms;
}
.circle-container:nth-child(124) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-124;
    animation-name: move-frames-124;
    -webkit-animation-duration: 32810ms;
    animation-duration: 32810ms;
    -webkit-animation-delay: 14962ms;
    animation-delay: 14962ms;
}
@-webkit-keyframes move-frames-124 {
    from {
        transform: translate3d(42vw, 109vh, 0);
    }
    to {
        transform: translate3d(44vw, -117vh, 0);
    }
}
@keyframes move-frames-124 {
    from {
        transform: translate3d(42vw, 109vh, 0);
    }
    to {
        transform: translate3d(44vw, -117vh, 0);
    }
}
.circle-container:nth-child(124) .circle {
    -webkit-animation-delay: 784ms;
    animation-delay: 784ms;
}
.circle-container:nth-child(125) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-125;
    animation-name: move-frames-125;
    -webkit-animation-duration: 31168ms;
    animation-duration: 31168ms;
    -webkit-animation-delay: 14908ms;
    animation-delay: 14908ms;
}
@-webkit-keyframes move-frames-125 {
    from {
        transform: translate3d(44vw, 108vh, 0);
    }
    to {
        transform: translate3d(56vw, -129vh, 0);
    }
}
@keyframes move-frames-125 {
    from {
        transform: translate3d(44vw, 108vh, 0);
    }
    to {
        transform: translate3d(56vw, -129vh, 0);
    }
}
.circle-container:nth-child(125) .circle {
    -webkit-animation-delay: 3221ms;
    animation-delay: 3221ms;
}
.circle-container:nth-child(126) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-126;
    animation-name: move-frames-126;
    -webkit-animation-duration: 33120ms;
    animation-duration: 33120ms;
    -webkit-animation-delay: 16275ms;
    animation-delay: 16275ms;
}
@-webkit-keyframes move-frames-126 {
    from {
        transform: translate3d(30vw, 109vh, 0);
    }
    to {
        transform: translate3d(47vw, -118vh, 0);
    }
}
@keyframes move-frames-126 {
    from {
        transform: translate3d(30vw, 109vh, 0);
    }
    to {
        transform: translate3d(47vw, -118vh, 0);
    }
}
.circle-container:nth-child(126) .circle {
    -webkit-animation-delay: 1717ms;
    animation-delay: 1717ms;
}
.circle-container:nth-child(127) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-127;
    animation-name: move-frames-127;
    -webkit-animation-duration: 34512ms;
    animation-duration: 34512ms;
    -webkit-animation-delay: 33652ms;
    animation-delay: 33652ms;
}
@-webkit-keyframes move-frames-127 {
    from {
        transform: translate3d(64vw, 104vh, 0);
    }
    to {
        transform: translate3d(47vw, -133vh, 0);
    }
}
@keyframes move-frames-127 {
    from {
        transform: translate3d(64vw, 104vh, 0);
    }
    to {
        transform: translate3d(47vw, -133vh, 0);
    }
}
.circle-container:nth-child(127) .circle {
    -webkit-animation-delay: 1644ms;
    animation-delay: 1644ms;
}
.circle-container:nth-child(128) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-128;
    animation-name: move-frames-128;
    -webkit-animation-duration: 36306ms;
    animation-duration: 36306ms;
    -webkit-animation-delay: 16995ms;
    animation-delay: 16995ms;
}
@-webkit-keyframes move-frames-128 {
    from {
        transform: translate3d(54vw, 108vh, 0);
    }
    to {
        transform: translate3d(36vw, -123vh, 0);
    }
}
@keyframes move-frames-128 {
    from {
        transform: translate3d(54vw, 108vh, 0);
    }
    to {
        transform: translate3d(36vw, -123vh, 0);
    }
}
.circle-container:nth-child(128) .circle {
    -webkit-animation-delay: 289ms;
    animation-delay: 289ms;
}
.circle-container:nth-child(129) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-129;
    animation-name: move-frames-129;
    -webkit-animation-duration: 29521ms;
    animation-duration: 29521ms;
    -webkit-animation-delay: 36101ms;
    animation-delay: 36101ms;
}
@-webkit-keyframes move-frames-129 {
    from {
        transform: translate3d(98vw, 104vh, 0);
    }
    to {
        transform: translate3d(72vw, -120vh, 0);
    }
}
@keyframes move-frames-129 {
    from {
        transform: translate3d(98vw, 104vh, 0);
    }
    to {
        transform: translate3d(72vw, -120vh, 0);
    }
}
.circle-container:nth-child(129) .circle {
    -webkit-animation-delay: 3463ms;
    animation-delay: 3463ms;
}
.circle-container:nth-child(130) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-130;
    animation-name: move-frames-130;
    -webkit-animation-duration: 28963ms;
    animation-duration: 28963ms;
    -webkit-animation-delay: 34042ms;
    animation-delay: 34042ms;
}
@-webkit-keyframes move-frames-130 {
    from {
        transform: translate3d(7vw, 101vh, 0);
    }
    to {
        transform: translate3d(74vw, -122vh, 0);
    }
}
@keyframes move-frames-130 {
    from {
        transform: translate3d(7vw, 101vh, 0);
    }
    to {
        transform: translate3d(74vw, -122vh, 0);
    }
}
.circle-container:nth-child(130) .circle {
    -webkit-animation-delay: 2480ms;
    animation-delay: 2480ms;
}
.circle-container:nth-child(131) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-131;
    animation-name: move-frames-131;
    -webkit-animation-duration: 36580ms;
    animation-duration: 36580ms;
    -webkit-animation-delay: 4903ms;
    animation-delay: 4903ms;
}
@-webkit-keyframes move-frames-131 {
    from {
        transform: translate3d(97vw, 110vh, 0);
    }
    to {
        transform: translate3d(30vw, -122vh, 0);
    }
}
@keyframes move-frames-131 {
    from {
        transform: translate3d(97vw, 110vh, 0);
    }
    to {
        transform: translate3d(30vw, -122vh, 0);
    }
}
.circle-container:nth-child(131) .circle {
    -webkit-animation-delay: 2780ms;
    animation-delay: 2780ms;
}
.circle-container:nth-child(132) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-132;
    animation-name: move-frames-132;
    -webkit-animation-duration: 36743ms;
    animation-duration: 36743ms;
    -webkit-animation-delay: 23142ms;
    animation-delay: 23142ms;
}
@-webkit-keyframes move-frames-132 {
    from {
        transform: translate3d(63vw, 108vh, 0);
    }
    to {
        transform: translate3d(37vw, -137vh, 0);
    }
}
@keyframes move-frames-132 {
    from {
        transform: translate3d(63vw, 108vh, 0);
    }
    to {
        transform: translate3d(37vw, -137vh, 0);
    }
}
.circle-container:nth-child(132) .circle {
    -webkit-animation-delay: 3592ms;
    animation-delay: 3592ms;
}
.circle-container:nth-child(133) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-133;
    animation-name: move-frames-133;
    -webkit-animation-duration: 29058ms;
    animation-duration: 29058ms;
    -webkit-animation-delay: 12839ms;
    animation-delay: 12839ms;
}
@-webkit-keyframes move-frames-133 {
    from {
        transform: translate3d(91vw, 109vh, 0);
    }
    to {
        transform: translate3d(25vw, -138vh, 0);
    }
}
@keyframes move-frames-133 {
    from {
        transform: translate3d(91vw, 109vh, 0);
    }
    to {
        transform: translate3d(25vw, -138vh, 0);
    }
}
.circle-container:nth-child(133) .circle {
    -webkit-animation-delay: 3958ms;
    animation-delay: 3958ms;
}
.circle-container:nth-child(134) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-134;
    animation-name: move-frames-134;
    -webkit-animation-duration: 30000ms;
    animation-duration: 30000ms;
    -webkit-animation-delay: 14831ms;
    animation-delay: 14831ms;
}
@-webkit-keyframes move-frames-134 {
    from {
        transform: translate3d(58vw, 109vh, 0);
    }
    to {
        transform: translate3d(42vw, -134vh, 0);
    }
}
@keyframes move-frames-134 {
    from {
        transform: translate3d(58vw, 109vh, 0);
    }
    to {
        transform: translate3d(42vw, -134vh, 0);
    }
}
.circle-container:nth-child(134) .circle {
    -webkit-animation-delay: 663ms;
    animation-delay: 663ms;
}
.circle-container:nth-child(135) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-135;
    animation-name: move-frames-135;
    -webkit-animation-duration: 36204ms;
    animation-duration: 36204ms;
    -webkit-animation-delay: 1665ms;
    animation-delay: 1665ms;
}
@-webkit-keyframes move-frames-135 {
    from {
        transform: translate3d(3vw, 104vh, 0);
    }
    to {
        transform: translate3d(52vw, -128vh, 0);
    }
}
@keyframes move-frames-135 {
    from {
        transform: translate3d(3vw, 104vh, 0);
    }
    to {
        transform: translate3d(52vw, -128vh, 0);
    }
}
.circle-container:nth-child(135) .circle {
    -webkit-animation-delay: 576ms;
    animation-delay: 576ms;
}
.circle-container:nth-child(136) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-136;
    animation-name: move-frames-136;
    -webkit-animation-duration: 29114ms;
    animation-duration: 29114ms;
    -webkit-animation-delay: 8741ms;
    animation-delay: 8741ms;
}
@-webkit-keyframes move-frames-136 {
    from {
        transform: translate3d(90vw, 105vh, 0);
    }
    to {
        transform: translate3d(36vw, -130vh, 0);
    }
}
@keyframes move-frames-136 {
    from {
        transform: translate3d(90vw, 105vh, 0);
    }
    to {
        transform: translate3d(36vw, -130vh, 0);
    }
}
.circle-container:nth-child(136) .circle {
    -webkit-animation-delay: 1480ms;
    animation-delay: 1480ms;
}
.circle-container:nth-child(137) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-137;
    animation-name: move-frames-137;
    -webkit-animation-duration: 32976ms;
    animation-duration: 32976ms;
    -webkit-animation-delay: 32643ms;
    animation-delay: 32643ms;
}
@-webkit-keyframes move-frames-137 {
    from {
        transform: translate3d(2vw, 102vh, 0);
    }
    to {
        transform: translate3d(55vw, -115vh, 0);
    }
}
@keyframes move-frames-137 {
    from {
        transform: translate3d(2vw, 102vh, 0);
    }
    to {
        transform: translate3d(55vw, -115vh, 0);
    }
}
.circle-container:nth-child(137) .circle {
    -webkit-animation-delay: 2707ms;
    animation-delay: 2707ms;
}
.circle-container:nth-child(138) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-138;
    animation-name: move-frames-138;
    -webkit-animation-duration: 28722ms;
    animation-duration: 28722ms;
    -webkit-animation-delay: 24403ms;
    animation-delay: 24403ms;
}
@-webkit-keyframes move-frames-138 {
    from {
        transform: translate3d(15vw, 101vh, 0);
    }
    to {
        transform: translate3d(35vw, -126vh, 0);
    }
}
@keyframes move-frames-138 {
    from {
        transform: translate3d(15vw, 101vh, 0);
    }
    to {
        transform: translate3d(35vw, -126vh, 0);
    }
}
.circle-container:nth-child(138) .circle {
    -webkit-animation-delay: 1631ms;
    animation-delay: 1631ms;
}
.circle-container:nth-child(139) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-139;
    animation-name: move-frames-139;
    -webkit-animation-duration: 29161ms;
    animation-duration: 29161ms;
    -webkit-animation-delay: 18131ms;
    animation-delay: 18131ms;
}
@-webkit-keyframes move-frames-139 {
    from {
        transform: translate3d(5vw, 103vh, 0);
    }
    to {
        transform: translate3d(87vw, -133vh, 0);
    }
}
@keyframes move-frames-139 {
    from {
        transform: translate3d(5vw, 103vh, 0);
    }
    to {
        transform: translate3d(87vw, -133vh, 0);
    }
}
.circle-container:nth-child(139) .circle {
    -webkit-animation-delay: 2186ms;
    animation-delay: 2186ms;
}
.circle-container:nth-child(140) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-140;
    animation-name: move-frames-140;
    -webkit-animation-duration: 33814ms;
    animation-duration: 33814ms;
    -webkit-animation-delay: 5628ms;
    animation-delay: 5628ms;
}
@-webkit-keyframes move-frames-140 {
    from {
        transform: translate3d(6vw, 110vh, 0);
    }
    to {
        transform: translate3d(34vw, -122vh, 0);
    }
}
@keyframes move-frames-140 {
    from {
        transform: translate3d(6vw, 110vh, 0);
    }
    to {
        transform: translate3d(34vw, -122vh, 0);
    }
}
.circle-container:nth-child(140) .circle {
    -webkit-animation-delay: 3648ms;
    animation-delay: 3648ms;
}
.circle-container:nth-child(141) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-141;
    animation-name: move-frames-141;
    -webkit-animation-duration: 33457ms;
    animation-duration: 33457ms;
    -webkit-animation-delay: 17547ms;
    animation-delay: 17547ms;
}
@-webkit-keyframes move-frames-141 {
    from {
        transform: translate3d(61vw, 110vh, 0);
    }
    to {
        transform: translate3d(38vw, -137vh, 0);
    }
}
@keyframes move-frames-141 {
    from {
        transform: translate3d(61vw, 110vh, 0);
    }
    to {
        transform: translate3d(38vw, -137vh, 0);
    }
}
.circle-container:nth-child(141) .circle {
    -webkit-animation-delay: 2170ms;
    animation-delay: 2170ms;
}
.circle-container:nth-child(142) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-142;
    animation-name: move-frames-142;
    -webkit-animation-duration: 31705ms;
    animation-duration: 31705ms;
    -webkit-animation-delay: 26005ms;
    animation-delay: 26005ms;
}
@-webkit-keyframes move-frames-142 {
    from {
        transform: translate3d(32vw, 103vh, 0);
    }
    to {
        transform: translate3d(18vw, -116vh, 0);
    }
}
@keyframes move-frames-142 {
    from {
        transform: translate3d(32vw, 103vh, 0);
    }
    to {
        transform: translate3d(18vw, -116vh, 0);
    }
}
.circle-container:nth-child(142) .circle {
    -webkit-animation-delay: 2090ms;
    animation-delay: 2090ms;
}
.circle-container:nth-child(143) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-143;
    animation-name: move-frames-143;
    -webkit-animation-duration: 29391ms;
    animation-duration: 29391ms;
    -webkit-animation-delay: 984ms;
    animation-delay: 984ms;
}
@-webkit-keyframes move-frames-143 {
    from {
        transform: translate3d(22vw, 103vh, 0);
    }
    to {
        transform: translate3d(42vw, -126vh, 0);
    }
}
@keyframes move-frames-143 {
    from {
        transform: translate3d(22vw, 103vh, 0);
    }
    to {
        transform: translate3d(42vw, -126vh, 0);
    }
}
.circle-container:nth-child(143) .circle {
    -webkit-animation-delay: 2567ms;
    animation-delay: 2567ms;
}
.circle-container:nth-child(144) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-144;
    animation-name: move-frames-144;
    -webkit-animation-duration: 28343ms;
    animation-duration: 28343ms;
    -webkit-animation-delay: 20005ms;
    animation-delay: 20005ms;
}
@-webkit-keyframes move-frames-144 {
    from {
        transform: translate3d(71vw, 106vh, 0);
    }
    to {
        transform: translate3d(94vw, -121vh, 0);
    }
}
@keyframes move-frames-144 {
    from {
        transform: translate3d(71vw, 106vh, 0);
    }
    to {
        transform: translate3d(94vw, -121vh, 0);
    }
}
.circle-container:nth-child(144) .circle {
    -webkit-animation-delay: 2337ms;
    animation-delay: 2337ms;
}
.circle-container:nth-child(145) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-145;
    animation-name: move-frames-145;
    -webkit-animation-duration: 28117ms;
    animation-duration: 28117ms;
    -webkit-animation-delay: 17342ms;
    animation-delay: 17342ms;
}
@-webkit-keyframes move-frames-145 {
    from {
        transform: translate3d(76vw, 102vh, 0);
    }
    to {
        transform: translate3d(72vw, -109vh, 0);
    }
}
@keyframes move-frames-145 {
    from {
        transform: translate3d(76vw, 102vh, 0);
    }
    to {
        transform: translate3d(72vw, -109vh, 0);
    }
}
.circle-container:nth-child(145) .circle {
    -webkit-animation-delay: 2761ms;
    animation-delay: 2761ms;
}
.circle-container:nth-child(146) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-146;
    animation-name: move-frames-146;
    -webkit-animation-duration: 31339ms;
    animation-duration: 31339ms;
    -webkit-animation-delay: 11150ms;
    animation-delay: 11150ms;
}
@-webkit-keyframes move-frames-146 {
    from {
        transform: translate3d(67vw, 107vh, 0);
    }
    to {
        transform: translate3d(63vw, -120vh, 0);
    }
}
@keyframes move-frames-146 {
    from {
        transform: translate3d(67vw, 107vh, 0);
    }
    to {
        transform: translate3d(63vw, -120vh, 0);
    }
}
.circle-container:nth-child(146) .circle {
    -webkit-animation-delay: 523ms;
    animation-delay: 523ms;
}
.circle-container:nth-child(147) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-147;
    animation-name: move-frames-147;
    -webkit-animation-duration: 31628ms;
    animation-duration: 31628ms;
    -webkit-animation-delay: 24511ms;
    animation-delay: 24511ms;
}
@-webkit-keyframes move-frames-147 {
    from {
        transform: translate3d(93vw, 101vh, 0);
    }
    to {
        transform: translate3d(63vw, -119vh, 0);
    }
}
@keyframes move-frames-147 {
    from {
        transform: translate3d(93vw, 101vh, 0);
    }
    to {
        transform: translate3d(63vw, -119vh, 0);
    }
}
.circle-container:nth-child(147) .circle {
    -webkit-animation-delay: 1165ms;
    animation-delay: 1165ms;
}
.circle-container:nth-child(148) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-148;
    animation-name: move-frames-148;
    -webkit-animation-duration: 35309ms;
    animation-duration: 35309ms;
    -webkit-animation-delay: 33001ms;
    animation-delay: 33001ms;
}
@-webkit-keyframes move-frames-148 {
    from {
        transform: translate3d(70vw, 105vh, 0);
    }
    to {
        transform: translate3d(5vw, -131vh, 0);
    }
}
@keyframes move-frames-148 {
    from {
        transform: translate3d(70vw, 105vh, 0);
    }
    to {
        transform: translate3d(5vw, -131vh, 0);
    }
}
.circle-container:nth-child(148) .circle {
    -webkit-animation-delay: 3942ms;
    animation-delay: 3942ms;
}
.circle-container:nth-child(149) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-149;
    animation-name: move-frames-149;
    -webkit-animation-duration: 31103ms;
    animation-duration: 31103ms;
    -webkit-animation-delay: 11303ms;
    animation-delay: 11303ms;
}
@-webkit-keyframes move-frames-149 {
    from {
        transform: translate3d(78vw, 109vh, 0);
    }
    to {
        transform: translate3d(22vw, -131vh, 0);
    }
}
@keyframes move-frames-149 {
    from {
        transform: translate3d(78vw, 109vh, 0);
    }
    to {
        transform: translate3d(22vw, -131vh, 0);
    }
}
.circle-container:nth-child(149) .circle {
    -webkit-animation-delay: 1711ms;
    animation-delay: 1711ms;
}
.circle-container:nth-child(150) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-150;
    animation-name: move-frames-150;
    -webkit-animation-duration: 31540ms;
    animation-duration: 31540ms;
    -webkit-animation-delay: 1649ms;
    animation-delay: 1649ms;
}
@-webkit-keyframes move-frames-150 {
    from {
        transform: translate3d(5vw, 109vh, 0);
    }
    to {
        transform: translate3d(77vw, -117vh, 0);
    }
}
@keyframes move-frames-150 {
    from {
        transform: translate3d(5vw, 109vh, 0);
    }
    to {
        transform: translate3d(77vw, -117vh, 0);
    }
}
.circle-container:nth-child(150) .circle {
    -webkit-animation-delay: 1239ms;
    animation-delay: 1239ms;
}
.circle-container:nth-child(151) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-151;
    animation-name: move-frames-151;
    -webkit-animation-duration: 34238ms;
    animation-duration: 34238ms;
    -webkit-animation-delay: 31736ms;
    animation-delay: 31736ms;
}
@-webkit-keyframes move-frames-151 {
    from {
        transform: translate3d(42vw, 101vh, 0);
    }
    to {
        transform: translate3d(97vw, -120vh, 0);
    }
}
@keyframes move-frames-151 {
    from {
        transform: translate3d(42vw, 101vh, 0);
    }
    to {
        transform: translate3d(97vw, -120vh, 0);
    }
}
.circle-container:nth-child(151) .circle {
    -webkit-animation-delay: 3637ms;
    animation-delay: 3637ms;
}
.circle-container:nth-child(152) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-152;
    animation-name: move-frames-152;
    -webkit-animation-duration: 35027ms;
    animation-duration: 35027ms;
    -webkit-animation-delay: 21125ms;
    animation-delay: 21125ms;
}
@-webkit-keyframes move-frames-152 {
    from {
        transform: translate3d(90vw, 102vh, 0);
    }
    to {
        transform: translate3d(66vw, -126vh, 0);
    }
}
@keyframes move-frames-152 {
    from {
        transform: translate3d(90vw, 102vh, 0);
    }
    to {
        transform: translate3d(66vw, -126vh, 0);
    }
}
.circle-container:nth-child(152) .circle {
    -webkit-animation-delay: 1461ms;
    animation-delay: 1461ms;
}
.circle-container:nth-child(153) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-153;
    animation-name: move-frames-153;
    -webkit-animation-duration: 28532ms;
    animation-duration: 28532ms;
    -webkit-animation-delay: 18252ms;
    animation-delay: 18252ms;
}
@-webkit-keyframes move-frames-153 {
    from {
        transform: translate3d(53vw, 104vh, 0);
    }
    to {
        transform: translate3d(97vw, -127vh, 0);
    }
}
@keyframes move-frames-153 {
    from {
        transform: translate3d(53vw, 104vh, 0);
    }
    to {
        transform: translate3d(97vw, -127vh, 0);
    }
}
.circle-container:nth-child(153) .circle {
    -webkit-animation-delay: 2747ms;
    animation-delay: 2747ms;
}
.circle-container:nth-child(154) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-154;
    animation-name: move-frames-154;
    -webkit-animation-duration: 30286ms;
    animation-duration: 30286ms;
    -webkit-animation-delay: 34634ms;
    animation-delay: 34634ms;
}
@-webkit-keyframes move-frames-154 {
    from {
        transform: translate3d(15vw, 109vh, 0);
    }
    to {
        transform: translate3d(13vw, -126vh, 0);
    }
}
@keyframes move-frames-154 {
    from {
        transform: translate3d(15vw, 109vh, 0);
    }
    to {
        transform: translate3d(13vw, -126vh, 0);
    }
}
.circle-container:nth-child(154) .circle {
    -webkit-animation-delay: 3625ms;
    animation-delay: 3625ms;
}
.circle-container:nth-child(155) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-155;
    animation-name: move-frames-155;
    -webkit-animation-duration: 29997ms;
    animation-duration: 29997ms;
    -webkit-animation-delay: 33378ms;
    animation-delay: 33378ms;
}
@-webkit-keyframes move-frames-155 {
    from {
        transform: translate3d(11vw, 106vh, 0);
    }
    to {
        transform: translate3d(65vw, -121vh, 0);
    }
}
@keyframes move-frames-155 {
    from {
        transform: translate3d(11vw, 106vh, 0);
    }
    to {
        transform: translate3d(65vw, -121vh, 0);
    }
}
.circle-container:nth-child(155) .circle {
    -webkit-animation-delay: 1998ms;
    animation-delay: 1998ms;
}
.circle-container:nth-child(156) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-156;
    animation-name: move-frames-156;
    -webkit-animation-duration: 33158ms;
    animation-duration: 33158ms;
    -webkit-animation-delay: 21112ms;
    animation-delay: 21112ms;
}
@-webkit-keyframes move-frames-156 {
    from {
        transform: translate3d(83vw, 109vh, 0);
    }
    to {
        transform: translate3d(69vw, -113vh, 0);
    }
}
@keyframes move-frames-156 {
    from {
        transform: translate3d(83vw, 109vh, 0);
    }
    to {
        transform: translate3d(69vw, -113vh, 0);
    }
}
.circle-container:nth-child(156) .circle {
    -webkit-animation-delay: 3135ms;
    animation-delay: 3135ms;
}
.circle-container:nth-child(157) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-157;
    animation-name: move-frames-157;
    -webkit-animation-duration: 36370ms;
    animation-duration: 36370ms;
    -webkit-animation-delay: 29034ms;
    animation-delay: 29034ms;
}
@-webkit-keyframes move-frames-157 {
    from {
        transform: translate3d(9vw, 102vh, 0);
    }
    to {
        transform: translate3d(70vw, -105vh, 0);
    }
}
@keyframes move-frames-157 {
    from {
        transform: translate3d(9vw, 102vh, 0);
    }
    to {
        transform: translate3d(70vw, -105vh, 0);
    }
}
.circle-container:nth-child(157) .circle {
    -webkit-animation-delay: 1337ms;
    animation-delay: 1337ms;
}
.circle-container:nth-child(158) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-158;
    animation-name: move-frames-158;
    -webkit-animation-duration: 32762ms;
    animation-duration: 32762ms;
    -webkit-animation-delay: 4610ms;
    animation-delay: 4610ms;
}
@-webkit-keyframes move-frames-158 {
    from {
        transform: translate3d(53vw, 103vh, 0);
    }
    to {
        transform: translate3d(3vw, -107vh, 0);
    }
}
@keyframes move-frames-158 {
    from {
        transform: translate3d(53vw, 103vh, 0);
    }
    to {
        transform: translate3d(3vw, -107vh, 0);
    }
}
.circle-container:nth-child(158) .circle {
    -webkit-animation-delay: 1083ms;
    animation-delay: 1083ms;
}
.circle-container:nth-child(159) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-159;
    animation-name: move-frames-159;
    -webkit-animation-duration: 30976ms;
    animation-duration: 30976ms;
    -webkit-animation-delay: 8880ms;
    animation-delay: 8880ms;
}
@-webkit-keyframes move-frames-159 {
    from {
        transform: translate3d(47vw, 109vh, 0);
    }
    to {
        transform: translate3d(53vw, -124vh, 0);
    }
}
@keyframes move-frames-159 {
    from {
        transform: translate3d(47vw, 109vh, 0);
    }
    to {
        transform: translate3d(53vw, -124vh, 0);
    }
}
.circle-container:nth-child(159) .circle {
    -webkit-animation-delay: 3775ms;
    animation-delay: 3775ms;
}
.circle-container:nth-child(160) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-160;
    animation-name: move-frames-160;
    -webkit-animation-duration: 29718ms;
    animation-duration: 29718ms;
    -webkit-animation-delay: 32874ms;
    animation-delay: 32874ms;
}
@-webkit-keyframes move-frames-160 {
    from {
        transform: translate3d(21vw, 102vh, 0);
    }
    to {
        transform: translate3d(95vw, -107vh, 0);
    }
}
@keyframes move-frames-160 {
    from {
        transform: translate3d(21vw, 102vh, 0);
    }
    to {
        transform: translate3d(95vw, -107vh, 0);
    }
}
.circle-container:nth-child(160) .circle {
    -webkit-animation-delay: 2868ms;
    animation-delay: 2868ms;
}
.circle-container:nth-child(161) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-161;
    animation-name: move-frames-161;
    -webkit-animation-duration: 35260ms;
    animation-duration: 35260ms;
    -webkit-animation-delay: 15335ms;
    animation-delay: 15335ms;
}
@-webkit-keyframes move-frames-161 {
    from {
        transform: translate3d(41vw, 105vh, 0);
    }
    to {
        transform: translate3d(76vw, -118vh, 0);
    }
}
@keyframes move-frames-161 {
    from {
        transform: translate3d(41vw, 105vh, 0);
    }
    to {
        transform: translate3d(76vw, -118vh, 0);
    }
}
.circle-container:nth-child(161) .circle {
    -webkit-animation-delay: 1390ms;
    animation-delay: 1390ms;
}
.circle-container:nth-child(162) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-162;
    animation-name: move-frames-162;
    -webkit-animation-duration: 35031ms;
    animation-duration: 35031ms;
    -webkit-animation-delay: 6054ms;
    animation-delay: 6054ms;
}
@-webkit-keyframes move-frames-162 {
    from {
        transform: translate3d(53vw, 102vh, 0);
    }
    to {
        transform: translate3d(9vw, -108vh, 0);
    }
}
@keyframes move-frames-162 {
    from {
        transform: translate3d(53vw, 102vh, 0);
    }
    to {
        transform: translate3d(9vw, -108vh, 0);
    }
}
.circle-container:nth-child(162) .circle {
    -webkit-animation-delay: 3606ms;
    animation-delay: 3606ms;
}
.circle-container:nth-child(163) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-163;
    animation-name: move-frames-163;
    -webkit-animation-duration: 33174ms;
    animation-duration: 33174ms;
    -webkit-animation-delay: 2222ms;
    animation-delay: 2222ms;
}
@-webkit-keyframes move-frames-163 {
    from {
        transform: translate3d(61vw, 106vh, 0);
    }
    to {
        transform: translate3d(90vw, -123vh, 0);
    }
}
@keyframes move-frames-163 {
    from {
        transform: translate3d(61vw, 106vh, 0);
    }
    to {
        transform: translate3d(90vw, -123vh, 0);
    }
}
.circle-container:nth-child(163) .circle {
    -webkit-animation-delay: 1796ms;
    animation-delay: 1796ms;
}
.circle-container:nth-child(164) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-164;
    animation-name: move-frames-164;
    -webkit-animation-duration: 31770ms;
    animation-duration: 31770ms;
    -webkit-animation-delay: 34221ms;
    animation-delay: 34221ms;
}
@-webkit-keyframes move-frames-164 {
    from {
        transform: translate3d(41vw, 106vh, 0);
    }
    to {
        transform: translate3d(88vw, -113vh, 0);
    }
}
@keyframes move-frames-164 {
    from {
        transform: translate3d(41vw, 106vh, 0);
    }
    to {
        transform: translate3d(88vw, -113vh, 0);
    }
}
.circle-container:nth-child(164) .circle {
    -webkit-animation-delay: 2301ms;
    animation-delay: 2301ms;
}
.circle-container:nth-child(165) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-165;
    animation-name: move-frames-165;
    -webkit-animation-duration: 35623ms;
    animation-duration: 35623ms;
    -webkit-animation-delay: 17496ms;
    animation-delay: 17496ms;
}
@-webkit-keyframes move-frames-165 {
    from {
        transform: translate3d(81vw, 102vh, 0);
    }
    to {
        transform: translate3d(98vw, -127vh, 0);
    }
}
@keyframes move-frames-165 {
    from {
        transform: translate3d(81vw, 102vh, 0);
    }
    to {
        transform: translate3d(98vw, -127vh, 0);
    }
}
.circle-container:nth-child(165) .circle {
    -webkit-animation-delay: 1201ms;
    animation-delay: 1201ms;
}
.circle-container:nth-child(166) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-166;
    animation-name: move-frames-166;
    -webkit-animation-duration: 35047ms;
    animation-duration: 35047ms;
    -webkit-animation-delay: 17276ms;
    animation-delay: 17276ms;
}
@-webkit-keyframes move-frames-166 {
    from {
        transform: translate3d(94vw, 110vh, 0);
    }
    to {
        transform: translate3d(7vw, -128vh, 0);
    }
}
@keyframes move-frames-166 {
    from {
        transform: translate3d(94vw, 110vh, 0);
    }
    to {
        transform: translate3d(7vw, -128vh, 0);
    }
}
.circle-container:nth-child(166) .circle {
    -webkit-animation-delay: 2594ms;
    animation-delay: 2594ms;
}
.circle-container:nth-child(167) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-167;
    animation-name: move-frames-167;
    -webkit-animation-duration: 36485ms;
    animation-duration: 36485ms;
    -webkit-animation-delay: 18278ms;
    animation-delay: 18278ms;
}
@-webkit-keyframes move-frames-167 {
    from {
        transform: translate3d(4vw, 106vh, 0);
    }
    to {
        transform: translate3d(12vw, -107vh, 0);
    }
}
@keyframes move-frames-167 {
    from {
        transform: translate3d(4vw, 106vh, 0);
    }
    to {
        transform: translate3d(12vw, -107vh, 0);
    }
}
.circle-container:nth-child(167) .circle {
    -webkit-animation-delay: 2881ms;
    animation-delay: 2881ms;
}
.circle-container:nth-child(168) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-168;
    animation-name: move-frames-168;
    -webkit-animation-duration: 36951ms;
    animation-duration: 36951ms;
    -webkit-animation-delay: 3324ms;
    animation-delay: 3324ms;
}
@-webkit-keyframes move-frames-168 {
    from {
        transform: translate3d(90vw, 104vh, 0);
    }
    to {
        transform: translate3d(15vw, -126vh, 0);
    }
}
@keyframes move-frames-168 {
    from {
        transform: translate3d(90vw, 104vh, 0);
    }
    to {
        transform: translate3d(15vw, -126vh, 0);
    }
}
.circle-container:nth-child(168) .circle {
    -webkit-animation-delay: 2252ms;
    animation-delay: 2252ms;
}
.circle-container:nth-child(169) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-169;
    animation-name: move-frames-169;
    -webkit-animation-duration: 32712ms;
    animation-duration: 32712ms;
    -webkit-animation-delay: 19875ms;
    animation-delay: 19875ms;
}
@-webkit-keyframes move-frames-169 {
    from {
        transform: translate3d(4vw, 101vh, 0);
    }
    to {
        transform: translate3d(2vw, -108vh, 0);
    }
}
@keyframes move-frames-169 {
    from {
        transform: translate3d(4vw, 101vh, 0);
    }
    to {
        transform: translate3d(2vw, -108vh, 0);
    }
}
.circle-container:nth-child(169) .circle {
    -webkit-animation-delay: 989ms;
    animation-delay: 989ms;
}
.circle-container:nth-child(170) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-170;
    animation-name: move-frames-170;
    -webkit-animation-duration: 28112ms;
    animation-duration: 28112ms;
    -webkit-animation-delay: 6386ms;
    animation-delay: 6386ms;
}
@-webkit-keyframes move-frames-170 {
    from {
        transform: translate3d(37vw, 109vh, 0);
    }
    to {
        transform: translate3d(98vw, -112vh, 0);
    }
}
@keyframes move-frames-170 {
    from {
        transform: translate3d(37vw, 109vh, 0);
    }
    to {
        transform: translate3d(98vw, -112vh, 0);
    }
}
.circle-container:nth-child(170) .circle {
    -webkit-animation-delay: 3326ms;
    animation-delay: 3326ms;
}
.circle-container:nth-child(171) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-171;
    animation-name: move-frames-171;
    -webkit-animation-duration: 34315ms;
    animation-duration: 34315ms;
    -webkit-animation-delay: 24303ms;
    animation-delay: 24303ms;
}
@-webkit-keyframes move-frames-171 {
    from {
        transform: translate3d(8vw, 110vh, 0);
    }
    to {
        transform: translate3d(46vw, -119vh, 0);
    }
}
@keyframes move-frames-171 {
    from {
        transform: translate3d(8vw, 110vh, 0);
    }
    to {
        transform: translate3d(46vw, -119vh, 0);
    }
}
.circle-container:nth-child(171) .circle {
    -webkit-animation-delay: 1263ms;
    animation-delay: 1263ms;
}
.circle-container:nth-child(172) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-172;
    animation-name: move-frames-172;
    -webkit-animation-duration: 36024ms;
    animation-duration: 36024ms;
    -webkit-animation-delay: 8301ms;
    animation-delay: 8301ms;
}
@-webkit-keyframes move-frames-172 {
    from {
        transform: translate3d(91vw, 108vh, 0);
    }
    to {
        transform: translate3d(10vw, -121vh, 0);
    }
}
@keyframes move-frames-172 {
    from {
        transform: translate3d(91vw, 108vh, 0);
    }
    to {
        transform: translate3d(10vw, -121vh, 0);
    }
}
.circle-container:nth-child(172) .circle {
    -webkit-animation-delay: 2487ms;
    animation-delay: 2487ms;
}
.circle-container:nth-child(173) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-173;
    animation-name: move-frames-173;
    -webkit-animation-duration: 28124ms;
    animation-duration: 28124ms;
    -webkit-animation-delay: 29569ms;
    animation-delay: 29569ms;
}
@-webkit-keyframes move-frames-173 {
    from {
        transform: translate3d(52vw, 108vh, 0);
    }
    to {
        transform: translate3d(89vw, -124vh, 0);
    }
}
@keyframes move-frames-173 {
    from {
        transform: translate3d(52vw, 108vh, 0);
    }
    to {
        transform: translate3d(89vw, -124vh, 0);
    }
}
.circle-container:nth-child(173) .circle {
    -webkit-animation-delay: 2092ms;
    animation-delay: 2092ms;
}
.circle-container:nth-child(174) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-174;
    animation-name: move-frames-174;
    -webkit-animation-duration: 35333ms;
    animation-duration: 35333ms;
    -webkit-animation-delay: 34738ms;
    animation-delay: 34738ms;
}
@-webkit-keyframes move-frames-174 {
    from {
        transform: translate3d(49vw, 109vh, 0);
    }
    to {
        transform: translate3d(62vw, -120vh, 0);
    }
}
@keyframes move-frames-174 {
    from {
        transform: translate3d(49vw, 109vh, 0);
    }
    to {
        transform: translate3d(62vw, -120vh, 0);
    }
}
.circle-container:nth-child(174) .circle {
    -webkit-animation-delay: 1484ms;
    animation-delay: 1484ms;
}
.circle-container:nth-child(175) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-175;
    animation-name: move-frames-175;
    -webkit-animation-duration: 28793ms;
    animation-duration: 28793ms;
    -webkit-animation-delay: 16465ms;
    animation-delay: 16465ms;
}
@-webkit-keyframes move-frames-175 {
    from {
        transform: translate3d(44vw, 103vh, 0);
    }
    to {
        transform: translate3d(61vw, -105vh, 0);
    }
}
@keyframes move-frames-175 {
    from {
        transform: translate3d(44vw, 103vh, 0);
    }
    to {
        transform: translate3d(61vw, -105vh, 0);
    }
}
.circle-container:nth-child(175) .circle {
    -webkit-animation-delay: 1572ms;
    animation-delay: 1572ms;
}
.circle-container:nth-child(176) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-176;
    animation-name: move-frames-176;
    -webkit-animation-duration: 31435ms;
    animation-duration: 31435ms;
    -webkit-animation-delay: 30414ms;
    animation-delay: 30414ms;
}
@-webkit-keyframes move-frames-176 {
    from {
        transform: translate3d(62vw, 102vh, 0);
    }
    to {
        transform: translate3d(16vw, -109vh, 0);
    }
}
@keyframes move-frames-176 {
    from {
        transform: translate3d(62vw, 102vh, 0);
    }
    to {
        transform: translate3d(16vw, -109vh, 0);
    }
}
.circle-container:nth-child(176) .circle {
    -webkit-animation-delay: 1907ms;
    animation-delay: 1907ms;
}
.circle-container:nth-child(177) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-177;
    animation-name: move-frames-177;
    -webkit-animation-duration: 35410ms;
    animation-duration: 35410ms;
    -webkit-animation-delay: 91ms;
    animation-delay: 91ms;
}
@-webkit-keyframes move-frames-177 {
    from {
        transform: translate3d(5vw, 107vh, 0);
    }
    to {
        transform: translate3d(54vw, -113vh, 0);
    }
}
@keyframes move-frames-177 {
    from {
        transform: translate3d(5vw, 107vh, 0);
    }
    to {
        transform: translate3d(54vw, -113vh, 0);
    }
}
.circle-container:nth-child(177) .circle {
    -webkit-animation-delay: 635ms;
    animation-delay: 635ms;
}
.circle-container:nth-child(178) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-178;
    animation-name: move-frames-178;
    -webkit-animation-duration: 28894ms;
    animation-duration: 28894ms;
    -webkit-animation-delay: 2832ms;
    animation-delay: 2832ms;
}
@-webkit-keyframes move-frames-178 {
    from {
        transform: translate3d(91vw, 109vh, 0);
    }
    to {
        transform: translate3d(96vw, -122vh, 0);
    }
}
@keyframes move-frames-178 {
    from {
        transform: translate3d(91vw, 109vh, 0);
    }
    to {
        transform: translate3d(96vw, -122vh, 0);
    }
}
.circle-container:nth-child(178) .circle {
    -webkit-animation-delay: 2211ms;
    animation-delay: 2211ms;
}
.circle-container:nth-child(179) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-179;
    animation-name: move-frames-179;
    -webkit-animation-duration: 35069ms;
    animation-duration: 35069ms;
    -webkit-animation-delay: 19732ms;
    animation-delay: 19732ms;
}
@-webkit-keyframes move-frames-179 {
    from {
        transform: translate3d(60vw, 109vh, 0);
    }
    to {
        transform: translate3d(9vw, -110vh, 0);
    }
}
@keyframes move-frames-179 {
    from {
        transform: translate3d(60vw, 109vh, 0);
    }
    to {
        transform: translate3d(9vw, -110vh, 0);
    }
}
.circle-container:nth-child(179) .circle {
    -webkit-animation-delay: 1137ms;
    animation-delay: 1137ms;
}
.circle-container:nth-child(180) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-180;
    animation-name: move-frames-180;
    -webkit-animation-duration: 35490ms;
    animation-duration: 35490ms;
    -webkit-animation-delay: 34625ms;
    animation-delay: 34625ms;
}
@-webkit-keyframes move-frames-180 {
    from {
        transform: translate3d(40vw, 108vh, 0);
    }
    to {
        transform: translate3d(83vw, -111vh, 0);
    }
}
@keyframes move-frames-180 {
    from {
        transform: translate3d(40vw, 108vh, 0);
    }
    to {
        transform: translate3d(83vw, -111vh, 0);
    }
}
.circle-container:nth-child(180) .circle {
    -webkit-animation-delay: 3554ms;
    animation-delay: 3554ms;
}
.circle-container:nth-child(181) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-181;
    animation-name: move-frames-181;
    -webkit-animation-duration: 28676ms;
    animation-duration: 28676ms;
    -webkit-animation-delay: 5413ms;
    animation-delay: 5413ms;
}
@-webkit-keyframes move-frames-181 {
    from {
        transform: translate3d(74vw, 108vh, 0);
    }
    to {
        transform: translate3d(70vw, -124vh, 0);
    }
}
@keyframes move-frames-181 {
    from {
        transform: translate3d(74vw, 108vh, 0);
    }
    to {
        transform: translate3d(70vw, -124vh, 0);
    }
}
.circle-container:nth-child(181) .circle {
    -webkit-animation-delay: 1688ms;
    animation-delay: 1688ms;
}
.circle-container:nth-child(182) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-182;
    animation-name: move-frames-182;
    -webkit-animation-duration: 34116ms;
    animation-duration: 34116ms;
    -webkit-animation-delay: 33475ms;
    animation-delay: 33475ms;
}
@-webkit-keyframes move-frames-182 {
    from {
        transform: translate3d(64vw, 105vh, 0);
    }
    to {
        transform: translate3d(3vw, -131vh, 0);
    }
}
@keyframes move-frames-182 {
    from {
        transform: translate3d(64vw, 105vh, 0);
    }
    to {
        transform: translate3d(3vw, -131vh, 0);
    }
}
.circle-container:nth-child(182) .circle {
    -webkit-animation-delay: 682ms;
    animation-delay: 682ms;
}
.circle-container:nth-child(183) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-183;
    animation-name: move-frames-183;
    -webkit-animation-duration: 36043ms;
    animation-duration: 36043ms;
    -webkit-animation-delay: 30634ms;
    animation-delay: 30634ms;
}
@-webkit-keyframes move-frames-183 {
    from {
        transform: translate3d(71vw, 104vh, 0);
    }
    to {
        transform: translate3d(91vw, -123vh, 0);
    }
}
@keyframes move-frames-183 {
    from {
        transform: translate3d(71vw, 104vh, 0);
    }
    to {
        transform: translate3d(91vw, -123vh, 0);
    }
}
.circle-container:nth-child(183) .circle {
    -webkit-animation-delay: 829ms;
    animation-delay: 829ms;
}
.circle-container:nth-child(184) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-184;
    animation-name: move-frames-184;
    -webkit-animation-duration: 36232ms;
    animation-duration: 36232ms;
    -webkit-animation-delay: 32137ms;
    animation-delay: 32137ms;
}
@-webkit-keyframes move-frames-184 {
    from {
        transform: translate3d(77vw, 109vh, 0);
    }
    to {
        transform: translate3d(4vw, -129vh, 0);
    }
}
@keyframes move-frames-184 {
    from {
        transform: translate3d(77vw, 109vh, 0);
    }
    to {
        transform: translate3d(4vw, -129vh, 0);
    }
}
.circle-container:nth-child(184) .circle {
    -webkit-animation-delay: 531ms;
    animation-delay: 531ms;
}
.circle-container:nth-child(185) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-185;
    animation-name: move-frames-185;
    -webkit-animation-duration: 35706ms;
    animation-duration: 35706ms;
    -webkit-animation-delay: 33914ms;
    animation-delay: 33914ms;
}
@-webkit-keyframes move-frames-185 {
    from {
        transform: translate3d(56vw, 107vh, 0);
    }
    to {
        transform: translate3d(3vw, -123vh, 0);
    }
}
@keyframes move-frames-185 {
    from {
        transform: translate3d(56vw, 107vh, 0);
    }
    to {
        transform: translate3d(3vw, -123vh, 0);
    }
}
.circle-container:nth-child(185) .circle {
    -webkit-animation-delay: 1338ms;
    animation-delay: 1338ms;
}
.circle-container:nth-child(186) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-186;
    animation-name: move-frames-186;
    -webkit-animation-duration: 34840ms;
    animation-duration: 34840ms;
    -webkit-animation-delay: 23510ms;
    animation-delay: 23510ms;
}
@-webkit-keyframes move-frames-186 {
    from {
        transform: translate3d(49vw, 110vh, 0);
    }
    to {
        transform: translate3d(78vw, -111vh, 0);
    }
}
@keyframes move-frames-186 {
    from {
        transform: translate3d(49vw, 110vh, 0);
    }
    to {
        transform: translate3d(78vw, -111vh, 0);
    }
}
.circle-container:nth-child(186) .circle {
    -webkit-animation-delay: 1601ms;
    animation-delay: 1601ms;
}
.circle-container:nth-child(187) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-187;
    animation-name: move-frames-187;
    -webkit-animation-duration: 30152ms;
    animation-duration: 30152ms;
    -webkit-animation-delay: 28403ms;
    animation-delay: 28403ms;
}
@-webkit-keyframes move-frames-187 {
    from {
        transform: translate3d(98vw, 106vh, 0);
    }
    to {
        transform: translate3d(61vw, -115vh, 0);
    }
}
@keyframes move-frames-187 {
    from {
        transform: translate3d(98vw, 106vh, 0);
    }
    to {
        transform: translate3d(61vw, -115vh, 0);
    }
}
.circle-container:nth-child(187) .circle {
    -webkit-animation-delay: 2920ms;
    animation-delay: 2920ms;
}
.circle-container:nth-child(188) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-188;
    animation-name: move-frames-188;
    -webkit-animation-duration: 34225ms;
    animation-duration: 34225ms;
    -webkit-animation-delay: 3545ms;
    animation-delay: 3545ms;
}
@-webkit-keyframes move-frames-188 {
    from {
        transform: translate3d(77vw, 110vh, 0);
    }
    to {
        transform: translate3d(52vw, -120vh, 0);
    }
}
@keyframes move-frames-188 {
    from {
        transform: translate3d(77vw, 110vh, 0);
    }
    to {
        transform: translate3d(52vw, -120vh, 0);
    }
}
.circle-container:nth-child(188) .circle {
    -webkit-animation-delay: 2153ms;
    animation-delay: 2153ms;
}
.circle-container:nth-child(189) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-189;
    animation-name: move-frames-189;
    -webkit-animation-duration: 29289ms;
    animation-duration: 29289ms;
    -webkit-animation-delay: 16508ms;
    animation-delay: 16508ms;
}
@-webkit-keyframes move-frames-189 {
    from {
        transform: translate3d(64vw, 103vh, 0);
    }
    to {
        transform: translate3d(21vw, -104vh, 0);
    }
}
@keyframes move-frames-189 {
    from {
        transform: translate3d(64vw, 103vh, 0);
    }
    to {
        transform: translate3d(21vw, -104vh, 0);
    }
}
.circle-container:nth-child(189) .circle {
    -webkit-animation-delay: 3556ms;
    animation-delay: 3556ms;
}
.circle-container:nth-child(190) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-190;
    animation-name: move-frames-190;
    -webkit-animation-duration: 36753ms;
    animation-duration: 36753ms;
    -webkit-animation-delay: 23380ms;
    animation-delay: 23380ms;
}
@-webkit-keyframes move-frames-190 {
    from {
        transform: translate3d(30vw, 107vh, 0);
    }
    to {
        transform: translate3d(29vw, -116vh, 0);
    }
}
@keyframes move-frames-190 {
    from {
        transform: translate3d(30vw, 107vh, 0);
    }
    to {
        transform: translate3d(29vw, -116vh, 0);
    }
}
.circle-container:nth-child(190) .circle {
    -webkit-animation-delay: 1876ms;
    animation-delay: 1876ms;
}
.circle-container:nth-child(191) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-191;
    animation-name: move-frames-191;
    -webkit-animation-duration: 30553ms;
    animation-duration: 30553ms;
    -webkit-animation-delay: 1615ms;
    animation-delay: 1615ms;
}
@-webkit-keyframes move-frames-191 {
    from {
        transform: translate3d(97vw, 102vh, 0);
    }
    to {
        transform: translate3d(68vw, -128vh, 0);
    }
}
@keyframes move-frames-191 {
    from {
        transform: translate3d(97vw, 102vh, 0);
    }
    to {
        transform: translate3d(68vw, -128vh, 0);
    }
}
.circle-container:nth-child(191) .circle {
    -webkit-animation-delay: 2109ms;
    animation-delay: 2109ms;
}
.circle-container:nth-child(192) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-192;
    animation-name: move-frames-192;
    -webkit-animation-duration: 36623ms;
    animation-duration: 36623ms;
    -webkit-animation-delay: 8972ms;
    animation-delay: 8972ms;
}
@-webkit-keyframes move-frames-192 {
    from {
        transform: translate3d(30vw, 101vh, 0);
    }
    to {
        transform: translate3d(88vw, -117vh, 0);
    }
}
@keyframes move-frames-192 {
    from {
        transform: translate3d(30vw, 101vh, 0);
    }
    to {
        transform: translate3d(88vw, -117vh, 0);
    }
}
.circle-container:nth-child(192) .circle {
    -webkit-animation-delay: 3952ms;
    animation-delay: 3952ms;
}
.circle-container:nth-child(193) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-193;
    animation-name: move-frames-193;
    -webkit-animation-duration: 35303ms;
    animation-duration: 35303ms;
    -webkit-animation-delay: 28639ms;
    animation-delay: 28639ms;
}
@-webkit-keyframes move-frames-193 {
    from {
        transform: translate3d(26vw, 108vh, 0);
    }
    to {
        transform: translate3d(56vw, -124vh, 0);
    }
}
@keyframes move-frames-193 {
    from {
        transform: translate3d(26vw, 108vh, 0);
    }
    to {
        transform: translate3d(56vw, -124vh, 0);
    }
}
.circle-container:nth-child(193) .circle {
    -webkit-animation-delay: 1925ms;
    animation-delay: 1925ms;
}
.circle-container:nth-child(194) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-194;
    animation-name: move-frames-194;
    -webkit-animation-duration: 29497ms;
    animation-duration: 29497ms;
    -webkit-animation-delay: 8521ms;
    animation-delay: 8521ms;
}
@-webkit-keyframes move-frames-194 {
    from {
        transform: translate3d(67vw, 104vh, 0);
    }
    to {
        transform: translate3d(42vw, -133vh, 0);
    }
}
@keyframes move-frames-194 {
    from {
        transform: translate3d(67vw, 104vh, 0);
    }
    to {
        transform: translate3d(42vw, -133vh, 0);
    }
}
.circle-container:nth-child(194) .circle {
    -webkit-animation-delay: 2292ms;
    animation-delay: 2292ms;
}
.circle-container:nth-child(195) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-195;
    animation-name: move-frames-195;
    -webkit-animation-duration: 34490ms;
    animation-duration: 34490ms;
    -webkit-animation-delay: 18875ms;
    animation-delay: 18875ms;
}
@-webkit-keyframes move-frames-195 {
    from {
        transform: translate3d(44vw, 109vh, 0);
    }
    to {
        transform: translate3d(25vw, -123vh, 0);
    }
}
@keyframes move-frames-195 {
    from {
        transform: translate3d(44vw, 109vh, 0);
    }
    to {
        transform: translate3d(25vw, -123vh, 0);
    }
}
.circle-container:nth-child(195) .circle {
    -webkit-animation-delay: 1860ms;
    animation-delay: 1860ms;
}
.circle-container:nth-child(196) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-196;
    animation-name: move-frames-196;
    -webkit-animation-duration: 35406ms;
    animation-duration: 35406ms;
    -webkit-animation-delay: 21216ms;
    animation-delay: 21216ms;
}
@-webkit-keyframes move-frames-196 {
    from {
        transform: translate3d(33vw, 106vh, 0);
    }
    to {
        transform: translate3d(1vw, -107vh, 0);
    }
}
@keyframes move-frames-196 {
    from {
        transform: translate3d(33vw, 106vh, 0);
    }
    to {
        transform: translate3d(1vw, -107vh, 0);
    }
}
.circle-container:nth-child(196) .circle {
    -webkit-animation-delay: 2402ms;
    animation-delay: 2402ms;
}
.circle-container:nth-child(197) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-197;
    animation-name: move-frames-197;
    -webkit-animation-duration: 34621ms;
    animation-duration: 34621ms;
    -webkit-animation-delay: 4240ms;
    animation-delay: 4240ms;
}
@-webkit-keyframes move-frames-197 {
    from {
        transform: translate3d(22vw, 110vh, 0);
    }
    to {
        transform: translate3d(19vw, -121vh, 0);
    }
}
@keyframes move-frames-197 {
    from {
        transform: translate3d(22vw, 110vh, 0);
    }
    to {
        transform: translate3d(19vw, -121vh, 0);
    }
}
.circle-container:nth-child(197) .circle {
    -webkit-animation-delay: 3690ms;
    animation-delay: 3690ms;
}
.circle-container:nth-child(198) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-198;
    animation-name: move-frames-198;
    -webkit-animation-duration: 31546ms;
    animation-duration: 31546ms;
    -webkit-animation-delay: 6498ms;
    animation-delay: 6498ms;
}
@-webkit-keyframes move-frames-198 {
    from {
        transform: translate3d(86vw, 101vh, 0);
    }
    to {
        transform: translate3d(72vw, -106vh, 0);
    }
}
@keyframes move-frames-198 {
    from {
        transform: translate3d(86vw, 101vh, 0);
    }
    to {
        transform: translate3d(72vw, -106vh, 0);
    }
}
.circle-container:nth-child(198) .circle {
    -webkit-animation-delay: 1549ms;
    animation-delay: 1549ms;
}
.circle-container:nth-child(199) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-199;
    animation-name: move-frames-199;
    -webkit-animation-duration: 28001ms;
    animation-duration: 28001ms;
    -webkit-animation-delay: 27249ms;
    animation-delay: 27249ms;
}
@-webkit-keyframes move-frames-199 {
    from {
        transform: translate3d(3vw, 105vh, 0);
    }
    to {
        transform: translate3d(64vw, -118vh, 0);
    }
}
@keyframes move-frames-199 {
    from {
        transform: translate3d(3vw, 105vh, 0);
    }
    to {
        transform: translate3d(64vw, -118vh, 0);
    }
}
.circle-container:nth-child(199) .circle {
    -webkit-animation-delay: 3841ms;
    animation-delay: 3841ms;
}
.circle-container:nth-child(200) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-200;
    animation-name: move-frames-200;
    -webkit-animation-duration: 35648ms;
    animation-duration: 35648ms;
    -webkit-animation-delay: 22505ms;
    animation-delay: 22505ms;
}
@-webkit-keyframes move-frames-200 {
    from {
        transform: translate3d(6vw, 105vh, 0);
    }
    to {
        transform: translate3d(69vw, -109vh, 0);
    }
}
@keyframes move-frames-200 {
    from {
        transform: translate3d(6vw, 105vh, 0);
    }
    to {
        transform: translate3d(69vw, -109vh, 0);
    }
}
.circle-container:nth-child(200) .circle {
    -webkit-animation-delay: 3447ms;
    animation-delay: 3447ms;
}

.message {
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: white;
    font-family: "Josefin Slab", serif;
    line-height: 27px;
    font-size: 18px;
    text-align: right;
    pointer-events: none;
    -webkit-animation: message-frames 1.5s ease 5s forwards;
    animation: message-frames 1.5s ease 5s forwards;
    opacity: 0;
}
@-webkit-keyframes message-frames {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes message-frames {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.team_cont_w .module_inner {overflow:hidden;}
.team_cont_w .module_cont  {width:202px; height:329px; opacity:0.5; bottom:-375px; transition: all 0.6s ease;}
.ouradvisor_list_w .module_cont {height:303px; bottom:-375px;}
.team_cont_w .module_list li.current .module_cont {bottom:0; opacity:1;}
.module_inner.current:nth-child(1) .module_cont{transition-delay: .1s !important;}
.module_inner.current:nth-child(2) .module_cont{transition-delay: .2s !important;}
.module_inner.current:nth-child(3) .module_cont{transition-delay: .3s !important;}
.module_inner.current:nth-child(4) .module_cont{transition-delay: .4s !important;}
.module_inner.current:nth-child(5) .module_cont{transition-delay: .5s !important;}
.module_inner.current:nth-child(6) .module_cont{transition-delay: .6s !important;}
.module_inner.current:nth-child(7) .module_cont{transition-delay: .7s !important;}
.module_inner.current:nth-child(8) .module_cont{transition-delay: .8s !important;}
.module_inner.current:nth-child(9) .module_cont{transition-delay: .9s !important;}
.module_inner.current:nth-child(10) .module_cont{transition-delay: 1s !important;}
.module_inner.current:nth-child(11) .module_cont{transition-delay: 1.1s !important;}
.module_inner.current:nth-child(12) .module_cont{transition-delay: 1.2s !important;}
.module_inner.current:nth-child(13) .module_cont{transition-delay: 1.3s !important;}
.module_inner.current:nth-child(14) .module_cont{transition-delay: 1.4s !important;}
.module_inner.current:nth-child(15) .module_cont{transition-delay: 1.5s !important;}
.module_inner.current:nth-child(16) .module_cont{transition-delay: 1.6s !important;}
.module_inner.current:nth-child(17) .module_cont{transition-delay: 1.7s !important;}
.module_inner.current:nth-child(18) .module_cont{transition-delay: 1.8s !important;}
.module_inner.current:nth-child(19) .module_cont{transition-delay: 1.9s !important;}
.module_inner.current:nth-child(20) .module_cont{transition-delay: 2s !important;}
